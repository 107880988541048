import { useCallback, useEffect, useState } from 'react';
import { useMutation, usePreloadedQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import NFTlogViewMutationType, {
  NFTlogViewMutation,
} from 'graphql/__generated__/NFTlogViewMutation.graphql';
import NFTSType, { NFTsQuery } from 'graphql/__generated__/NFTsQuery.graphql';
import {
  ContractNameEnum,
  GraphPageViewObjectType,
} from 'types/__generated__/graphql';

import { NFTType } from 'types/graphql/NFT';

import ProductContainer from './ProductContainer';
import ProductLayoutContainer from './ProductLayoutContainer';

export function useGetNFTFromPreloadedQuery(queryRef) {
  const { nfts } = usePreloadedQuery<NFTsQuery>(NFTSType, queryRef);
  const nft = nfts.edges.length > 0 ? nfts.edges[0].node : null;
  return nft;
}

function ProductPage() {
  const { nftSlug } = useParams();
  const [pageViewLogged, setPageViewLogged] = useState<boolean>(false);
  const [nftMetadata, setNFTMetaData] = useState<NFTType['metadata']>(null);
  // This fetches minted and non minted token incase not live product.

  const [nftViewMutation] = useMutation<NFTlogViewMutation>(
    NFTlogViewMutationType
  );

  const nftPageView = useCallback(() => {
    if (nftMetadata && !pageViewLogged) {
      setPageViewLogged(true);
      nftViewMutation({
        variables: {
          objectId: parseInt(nftMetadata.pk, 10),
          objectType: GraphPageViewObjectType.Nft,
        },
      });
    }
  }, [nftMetadata, pageViewLogged, nftViewMutation]);

  useEffect(() => {
    nftPageView();
  }, [nftPageView]);

  return (
    <ProductLayoutContainer id="productPage">
      <ProductContainer
        approvalRegistryContractQuery={{
          variables: { name: ContractNameEnum.ApprovedCreatorRegistry },
        }}
        nftsQuery={{
          variables: { first: 1, productSlug: nftSlug },
        }}
        transferCoreContractQuery={{
          variables: { name: ContractNameEnum.TransferCore },
        }}
        setNFTMetaData={setNFTMetaData}
      />
    </ProductLayoutContainer>
  );
}

export default ProductPage;
