import { useRef } from 'react';
import {
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery,
} from 'react-relay';

import { MPFonts, MPGrid } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import DigitalMediaOwnData, {
  DigitalMediaOwn$key,
} from 'graphql/__generated__/DigitalMediaOwn.graphql';
import GetOwnDigitalMedias, {
  OwnDigitalMedias,
} from 'graphql/__generated__/OwnDigitalMedias.graphql';

import DefaultSuspense, { DefaultLoader } from 'components/DefaultSuspense';
import ROUTES from 'constants/Routes';
import useInfiniteQueryScroll from 'hooks/useInfiniteQueryScroll';
import useLoadQuery from 'hooks/useLoadQuery';
import setDocTitle from 'utils/setDocTitle';

import * as styles from 'css/pages/store/creations/CreationPage.module.css';

interface CreationsListProps {
  allMediaRef: PreloadedQuery<OwnDigitalMedias, Record<string, unknown>>;
  burnedMediaRef: PreloadedQuery<OwnDigitalMedias, Record<string, unknown>>;
}

function CreationsList({ allMediaRef, burnedMediaRef }: CreationsListProps) {
  const visibilityRef = useRef(null);
  const scrollRef = useRef(null);
  const {
    data: allMediaData,
    hasMore: allMediaHasMore,
    loading: allMediaLoading,
  } = useInfiniteQueryScroll({
    pageSize: 10,
    paginatedQueryResults: usePaginationFragment<
      OwnDigitalMedias,
      DigitalMediaOwn$key
    >(
      DigitalMediaOwnData,
      usePreloadedQuery<OwnDigitalMedias>(GetOwnDigitalMedias, allMediaRef)
    ),
    ref: visibilityRef,
    scrollRef,
  });

  const { data: burnedMediaData, loading: burnedMediaLoading } =
    useInfiniteQueryScroll({
      pageSize: 10,
      paginatedQueryResults: usePaginationFragment<
        OwnDigitalMedias,
        DigitalMediaOwn$key
      >(
        DigitalMediaOwnData,
        usePreloadedQuery<OwnDigitalMedias>(GetOwnDigitalMedias, burnedMediaRef)
      ),
      ref: visibilityRef,
      scrollRef,
    });

  return (
    <div ref={scrollRef}>
      {allMediaData.length === 0 && !allMediaHasMore && (
        <div>
          <h5>You haven&#39;t uploaded any creations.</h5>
        </div>
      )}
      <MPGrid
        container
        spacing={{ desktop: 2, mobile: 1, wide: 3, xwide: 4 }}
        columns={{ desktop: 4, mobile: 1, wide: 4, xwide: 6 }}
      >
        {allMediaData.map((media) => (
          <MPGrid item mobile={1} desktop={1} wide={1} xwide={1} key={media.id}>
            <a
              href={ROUTES.DIGITAL_MEDIA.RELEASES.ID(media.pk)}
              className="defaultLink"
            >
              {media.media500x500PreviewContent ? (
                <img
                  src={media.media500x500PreviewContent}
                  className={styles.creationImg}
                />
              ) : (
                <span>Image not available</span>
              )}
            </a>
            <a
              href={ROUTES.DIGITAL_MEDIA.RELEASES.ID(media.pk)}
              className={joinClasses('defaultLink', MPFonts.attributeTitle)}
            >
              {media.title}
            </a>
            <div>
              <span className={MPFonts.attributeText}>
                {media.isEditable
                  ? 'Processing...'
                  : media.totalMissingCollabs > 0
                  ? 'Awaiting Approvals...'
                  : media.currentPrintIndex === 0
                  ? 'Publishing...'
                  : `${media.currentPrintIndex} of ${media.totalSupply} Published`}
              </span>
            </div>
          </MPGrid>
        ))}
      </MPGrid>
      {!allMediaHasMore && !allMediaLoading && !!burnedMediaData.length && (
        <>
          <div
            className={joinClasses(
              MPFonts.titleSmall,
              styles.deletedCreationsTitle
            )}
          >
            Deleted Creations
          </div>
          <MPGrid
            container
            spacing={{ desktop: 2, mobile: 1, wide: 3, xwide: 4 }}
            columns={{ desktop: 4, mobile: 1, wide: 4, xwide: 6 }}
          >
            {burnedMediaData.map((media) => (
              <MPGrid
                item
                mobile={1}
                desktop={1}
                wide={1}
                xwide={1}
                key={media.id}
              >
                <a
                  href={ROUTES.DIGITAL_MEDIA.RELEASES.ID(media.pk)}
                  className="defaultLink"
                >
                  {media.media500x500PreviewContent ? (
                    <img
                      src={media.media500x500PreviewContent}
                      className={styles.creationImg}
                    />
                  ) : (
                    <span>Image not available</span>
                  )}
                </a>
                <a
                  href={ROUTES.DIGITAL_MEDIA.RELEASES.ID(media.pk)}
                  className={joinClasses('defaultLink', MPFonts.attributeTitle)}
                >
                  {media.title}
                </a>
                <div>
                  <span className={MPFonts.attributeText}>
                    {`Released ${media.currentPrintIndex} of ${media.totalSupply}`}
                  </span>
                </div>
              </MPGrid>
            ))}
          </MPGrid>
        </>
      )}
      <div ref={visibilityRef}>&nbsp;</div>
      {!!(allMediaLoading || burnedMediaLoading) && <DefaultLoader />}
    </div>
  );
}

function Creations() {
  const [ownDigitalMediasRef] = useLoadQuery<OwnDigitalMedias>(
    GetOwnDigitalMedias,
    { first: 12, isBurned: false }
  );
  const [burnedOwnDigitalMediasRef] = useLoadQuery<OwnDigitalMedias>(
    GetOwnDigitalMedias,
    { first: 12, isBurned: true }
  );
  return ownDigitalMediasRef ? (
    <CreationsList
      allMediaRef={ownDigitalMediasRef}
      burnedMediaRef={burnedOwnDigitalMediasRef}
    />
  ) : null;
}

function CreationsPage() {
  setDocTitle('MakersPlace - Your Digital Creations');
  return (
    <div className={styles.creationPage}>
      <div className={joinClasses(MPFonts.titleMedium, styles.pageTitle)}>
        Your Creations
      </div>
      <DefaultSuspense>
        <Creations />
      </DefaultSuspense>
    </div>
  );
}

export default CreationsPage;
