import { useState } from 'react';
import { useMutation } from 'react-relay';
import { useAccount, useSignMessage, useWalletClient } from 'wagmi';

import AccountRegisterWallet, {
  AccountRegisterWalletMutation,
} from 'graphql/__generated__/AccountRegisterWalletMutation.graphql';

/**
 * Hook that signs a message indicating the user wishes to register a wallet and uploads
 * signature to server.
 */
export default function useRegisterWallet() {
  const { address } = useAccount();
  const { data: signer } = useWalletClient();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<Error>();

  const [registerWalletMutation] = useMutation<AccountRegisterWalletMutation>(
    AccountRegisterWallet
  );

  const { signMessageAsync } = useSignMessage();

  /**
   * @param signature string
   * @param isCreationEnabled boolean
   * @returns Result of uploading signature to server
   */
  const uploadSignature = async (
    signature: string,
    isCreationEnabled: boolean
  ) => {
    registerWalletMutation({
      onCompleted: () => {
        setIsLoading(false);
        setIsError(false);
        setError(null);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError(true);
        setError(e);
      },
      variables: { address, isCreationEnabled, signature },
    });
  };

  const registerWallet = async (isCreationEnabled: boolean) => {
    if (signer === null) {
      setError(new Error('Web3 connection error'));
      setIsError(true);
      return async () => 0;
    }
    setIsLoading(true);

    // TODO: handle case when signature fails for isLoading and error.
    const signature = await signMessageAsync({
      account: address,
      message: `Register digital wallet at address: ${address}`,
    });

    return uploadSignature(signature, isCreationEnabled).catch((e) => {
      // If this happens, make sure you're using WalletActionButton
      setIsError(true);
      setError(e);
    });
  };

  return {
    error,
    isError,
    isLoading,
    registerWallet,
  };
}
