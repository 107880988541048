import { CSSProperties, ElementType } from 'react';
import { Link } from 'react-router-dom';
import { isImageFilename } from 'utils/files';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { GenreEnum } from 'types/__generated__/graphql';

import ROUTES from 'constants/Routes';
import useVideo from 'hooks/useVideo';
import useHomepageGTM from 'utils/GTM/homepage';

import * as styles from 'css/pages/homepage/HomepageGenreCard.module.css';

interface HomepageGenreCardProps {
  isActive: boolean;
  mediaArtist: string;
  mediaTitle: string;
  mediaUrl: string;
  tag: string;
  title: string;
  as?: ElementType;
  className?: string;
  onMouseEnter?: (event: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;
  style?: CSSProperties;
}

function HomepageGenreCard({
  as: Tag = 'div',
  style,
  className,
  onMouseEnter,
  onMouseLeave,
  isActive,
  title,
  tag,
  mediaUrl,
  mediaTitle,
  mediaArtist,
}: HomepageGenreCardProps) {
  const track = useHomepageGTM();
  const { onMouseEnterHandler, setLoaded, videoRef } = useVideo({
    autoPlayOnLoad: true,
  });
  const defineRoute = (tagName: string) => {
    if (tagName === GenreEnum.Phygitals) return ROUTES.EXHIBITS.PHYGITALS();
    return ROUTES.EXPLORE.GENRE(tagName);
  };
  const handleMouseEnter = (event: MouseEvent) => {
    if (onMouseEnter) {
      onMouseEnter(event);
    }
    onMouseEnterHandler();
  };

  return (
    <Link
      to={defineRoute(tag)}
      onClick={() => track.clickGenre(title)}
      reloadDocument
    >
      <Tag
        className={joinClasses(
          styles.container,
          className,
          isActive ? styles.isActive : null
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onMouseLeave}
        style={style}
      >
        {isImageFilename(mediaUrl) ? (
          <img className={styles.media} src={mediaUrl} alt={title} />
        ) : (
          <video
            className={styles.media}
            ref={videoRef}
            playsInline
            loop
            muted
            onLoadedMetadata={setLoaded}
            onLoadedData={setLoaded}
          >
            <source src={mediaUrl} type="video/mp4" />
          </video>
        )}
        <div className={styles.overlay}>
          <div className={joinClasses(MPFonts.textNormalMedium, styles.title)}>
            {title}
          </div>

          <div className={styles.mediaInfo}>
            <div
              className={joinClasses(
                MPFonts.textSmallSemiBold,
                styles.mediaArtist
              )}
            >
              {mediaArtist}
            </div>
            <div
              className={joinClasses(MPFonts.paragraphSmall, styles.mediaTitle)}
            >
              {mediaTitle}
            </div>
          </div>
        </div>
      </Tag>
    </Link>
  );
}

export default HomepageGenreCard;
