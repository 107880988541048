/**
 * @generated SignedSource<<5c1e34db1cc1182feea560179ef72155>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountEmailVerificationQuery$variables = {};
export type AccountEmailVerificationQuery$data = {
  readonly emailVerification: {
    readonly hasVerified: boolean;
  } | null;
};
export type AccountEmailVerificationQuery = {
  variables: AccountEmailVerificationQuery$variables;
  response: AccountEmailVerificationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasVerified",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountEmailVerificationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailVerificationType",
        "kind": "LinkedField",
        "name": "emailVerification",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountEmailVerificationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailVerificationType",
        "kind": "LinkedField",
        "name": "emailVerification",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1b33e735439259dda0461e6ca2cb0dc",
    "id": null,
    "metadata": {},
    "name": "AccountEmailVerificationQuery",
    "operationKind": "query",
    "text": "query AccountEmailVerificationQuery {\n  emailVerification {\n    hasVerified\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "66bb3288d36511024126e1f280a62972";

export default node;
