/*
WARNING: This is a fork of SideNavBar.module.css intended only to maintain visual similarity
with the old Django templates. It should not be copied or otherwise used as a reference.
*/

.sideNav {
  text-align: left;
  height: 100%;
  width: 200px;
  background-color: var(--color-commonWhite);
  z-index: 5;
  overflow-y: auto;
  border-right: 1px solid var(--color-commonSelectedGray);
  flex-shrink: 0;
}

.navList {
  padding-inline-start: 0;
  list-style: none;
  display: inline-block;
  background-color: var(--color-commonWhite);
  width: 100%;
}

.logo {
  text-align: center;
  width: 188.5px;
  margin-bottom: 14px;
}

.logoBorder {
  border: 1px solid rgb(239, 239, 239);
  border-radius: 50%;
  width: 63px;
  height: 63px;
  margin-left: auto;
  margin-right: auto;
}

.seperator {
  border-bottom: 1px solid var(--color-commonSelectedGray);
}

.profileImage {
  width: 63px;
  height: 63px;
  border-radius: 9999px;
  z-index: 5;
  line-height: 21.6px;
  font-family: "NB Akademie Pro Light", sans-serif;
}

.navItem {
  margin-top: 3px;
  margin-bottom: 14px;
  padding: 0 0.75em;
  width: 100%;
  box-sizing: border-box;
  font-family: "NB Akademie Pro Light", sans-serif;
  font-size: 13.5px;
  font-weight: 300;
  line-height: 21.6px;
}

.navItem > a {
  color: rgb(48, 48, 48);
  text-decoration: none;
  padding-left: 20px;
}

.navItem > a:hover {
  text-decoration: underline;
  color: #ff8b53;
}

.navItemBold {
  font-family: "NB Akademie Pro Regular", sans-serif;
}

.navItemActive {
  font-family: "NB Akademie Pro Regular", sans-serif;
}

.navItemActive > a {
  text-decoration: underline rgb(48, 48, 48);
}
