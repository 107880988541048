import { Navigate, Route } from 'react-router-dom';

import WalletsAndApprovalsPage from './WalletsAndApprovalsPage';

function ProfileRedirect() {
  return <Navigate to="/store/edit/profile" replace />;
}

const ProfileRedirectRoute = (
  <Route path="profile">
    <Route
      path="wap"
      element={
        <WalletsAndApprovalsPage creatorContractsQuery={{ variables: {} }} />
      }
    />
    <Route path="*" element={<ProfileRedirect />} />
  </Route>
);

export default ProfileRedirectRoute;
