/**
 * @generated SignedSource<<c95558db7ff9ab17ae85e4d900ffc19b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountCreatorContractsQuery$variables = {};
export type AccountCreatorContractsQuery$data = {
  readonly creatorContracts: ReadonlyArray<{
    readonly abidata: string;
    readonly address: string;
    readonly approvedCreatorRegistryAddress: string;
    readonly approvedCreatorRegistryAbidata: string;
    readonly digitalMediaSaleCoreAddress: string;
    readonly dropSaleCoreAddress: string;
    readonly hasApprovedCreatorRegistryApproval: boolean;
    readonly hasOboApprovalForDigitalMediaSaleCore: boolean;
    readonly hasOboApprovalForDropSaleCore: boolean;
    readonly hasTransferCoreApproval: boolean;
    readonly hasVaultCoreApproval: boolean;
    readonly hasManifoldAdminApproval: boolean;
    readonly isManifold: boolean;
    readonly manifoldAdminAddress: string;
    readonly transferCoreAddress: string;
    readonly vaultCoreAddress: string;
  } | null> | null;
};
export type AccountCreatorContractsQuery = {
  variables: AccountCreatorContractsQuery$variables;
  response: AccountCreatorContractsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CreatorContractType",
    "kind": "LinkedField",
    "name": "creatorContracts",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "abidata",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "approvedCreatorRegistryAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "approvedCreatorRegistryAbidata",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "digitalMediaSaleCoreAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dropSaleCoreAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasApprovedCreatorRegistryApproval",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasOboApprovalForDigitalMediaSaleCore",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasOboApprovalForDropSaleCore",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasTransferCoreApproval",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasVaultCoreApproval",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasManifoldAdminApproval",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isManifold",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "manifoldAdminAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "transferCoreAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "vaultCoreAddress",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountCreatorContractsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountCreatorContractsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "2f2ebeb8f9531375e63ada605946fef8",
    "id": null,
    "metadata": {},
    "name": "AccountCreatorContractsQuery",
    "operationKind": "query",
    "text": "query AccountCreatorContractsQuery {\n  creatorContracts {\n    abidata\n    address\n    approvedCreatorRegistryAddress\n    approvedCreatorRegistryAbidata\n    digitalMediaSaleCoreAddress\n    dropSaleCoreAddress\n    hasApprovedCreatorRegistryApproval\n    hasOboApprovalForDigitalMediaSaleCore\n    hasOboApprovalForDropSaleCore\n    hasTransferCoreApproval\n    hasVaultCoreApproval\n    hasManifoldAdminApproval\n    isManifold\n    manifoldAdminAddress\n    transferCoreAddress\n    vaultCoreAddress\n  }\n}\n"
  }
};
})();

(node as any).hash = "917a4e7e078bfa0de2f1a0ab62d0ec43";

export default node;
