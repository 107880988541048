import { useRecoilValue } from 'recoil';
import { graphQLSelector } from 'recoil-relay';

import ConcreteNFTContractRequest from 'graphql/__generated__/NFTContractQuery.graphql';
import { ContractNameEnum } from 'types/__generated__/graphql';

import { NFTType } from 'types/graphql/NFT';

import RelayEnvironment from '../RelayEnvironment';

interface NFTBuyOfferDialogProps {
  nft: { listing: Pick<NFTType['listing'], 'shouldUseDropSaleContract'> };
}

export const RecoilSaleContractQuery = graphQLSelector({
  environment: RelayEnvironment,
  key: 'saleContract',
  mapResponse: (data) => data.nftContract,
  query: ConcreteNFTContractRequest,
  variables: () => ({ name: ContractNameEnum.DigitalMediaSaleCore }),
});

export const RecoilDropSaleContractQuery = graphQLSelector({
  environment: RelayEnvironment,
  key: 'dropSaleContract',
  mapResponse: (data) => data.nftContract,
  query: ConcreteNFTContractRequest,
  variables: () => ({ name: ContractNameEnum.DropSaleCore }),
});

export default function useSaleContract({ nft }: NFTBuyOfferDialogProps) {
  // If it's a drop related NFT, use the DropSaleCore contract, otherwise use the Sale contract
  const saleContract = useRecoilValue(RecoilSaleContractQuery);
  const dropSaleCoreContract = useRecoilValue(RecoilDropSaleContractQuery);

  return nft.listing.shouldUseDropSaleContract
    ? dropSaleCoreContract
    : saleContract;
}
