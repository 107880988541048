/**
 * @generated SignedSource<<c5a770407bf9d1cb1ae7e5a50fa97373>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RejectProductBidWithPersonalWalletInETHArguments = {
  bidId: number;
  fromAddress: string;
  nonce: number;
  transactionId: string;
};
export type NFTRejectProductBidWithPersonalWalletInETHMutation$variables = {
  requestData: RejectProductBidWithPersonalWalletInETHArguments;
};
export type NFTRejectProductBidWithPersonalWalletInETHMutation$data = {
  readonly rejectProductBidWithPersonalWalletInEth: {
    readonly success: boolean | null;
  } | null;
};
export type NFTRejectProductBidWithPersonalWalletInETHMutation = {
  variables: NFTRejectProductBidWithPersonalWalletInETHMutation$variables;
  response: NFTRejectProductBidWithPersonalWalletInETHMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "RejectProductBidWithPersonalWalletInETHMutation",
    "kind": "LinkedField",
    "name": "rejectProductBidWithPersonalWalletInEth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTRejectProductBidWithPersonalWalletInETHMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NFTRejectProductBidWithPersonalWalletInETHMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bdf20b289d0ad479f0aa1ec8f390ec9c",
    "id": null,
    "metadata": {},
    "name": "NFTRejectProductBidWithPersonalWalletInETHMutation",
    "operationKind": "mutation",
    "text": "mutation NFTRejectProductBidWithPersonalWalletInETHMutation(\n  $requestData: RejectProductBidWithPersonalWalletInETHArguments!\n) {\n  rejectProductBidWithPersonalWalletInEth(requestData: $requestData) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "585b3e3159362011b70df4486993ecbf";

export default node;
