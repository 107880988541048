/**
 * @generated SignedSource<<419e069323cc26f36b1c181b59c1acb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountChangePrimaryWalletMutation$variables = {
  address: string;
};
export type AccountChangePrimaryWalletMutation$data = {
  readonly changePrimaryWallet: {
    readonly success: boolean | null;
  } | null;
};
export type AccountChangePrimaryWalletMutation = {
  variables: AccountChangePrimaryWalletMutation$variables;
  response: AccountChangePrimaryWalletMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "address"
          }
        ],
        "kind": "ObjectValue",
        "name": "requestData"
      }
    ],
    "concreteType": "ChangePrimaryWalletMutation",
    "kind": "LinkedField",
    "name": "changePrimaryWallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountChangePrimaryWalletMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountChangePrimaryWalletMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0e53262562566c33b40467e0702f436c",
    "id": null,
    "metadata": {},
    "name": "AccountChangePrimaryWalletMutation",
    "operationKind": "mutation",
    "text": "mutation AccountChangePrimaryWalletMutation(\n  $address: HexString!\n) {\n  changePrimaryWallet(requestData: {address: $address}) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "5fc8de4797a3e2dd217104f8cb215f7a";

export default node;
