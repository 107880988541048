import { Route } from 'react-router-dom';

import CreationsPage from './creations/CreationPage';
import EditAccountPage from './editAccount/EditAccountPage';
import EditStorePage from './editStore/EditStorePage';
import GiftsPage from './gifts/GiftsPage';
import LiveOffersPage from './liveOffers/LiveOffersPage';
import ProductsPage from './products/ProductsPage';
import PurchaseHistoryPage from './purchaseHistory/purchaseHistoryPage';
import ReactionsPage from './reactions/ReactionsPage';
import RoyaltyHistoryPage from './royaltyHistory/royaltyHistoryPage';
import TransactionHistoryPage from './TransactionHistory/TransactionHistoryPage';
import StorePage from './StorePage';

const StoreRoute = (
  <Route path="store" element={<StorePage />}>
    <Route path="creations" element={<CreationsPage />} />
    <Route path="products" element={<ProductsPage />} />
    <Route path="live-offers" element={<LiveOffersPage />} />
    <Route path="purchase-history" element={<PurchaseHistoryPage />} />
    <Route path="royalty-history" element={<RoyaltyHistoryPage />} />
    <Route path="gifts" element={<GiftsPage />} />
    <Route path="reactions" element={<ReactionsPage />} />
    <Route path="transaction-history" element={<TransactionHistoryPage />} />
    <Route path="edit-store" element={<EditStorePage />} />
    <Route path="edit-account" element={<EditAccountPage />} />
  </Route>
);

export default StoreRoute;
