.tabs {
  width: 100%;
}

  /**
   * This is here as a test as we potentially have issues with how figma displays the fonts opposed to how they are supposed to be displayed.
   * Once we figure things out, this should be removed.
   */
.tabs :global(.headline4Font) {
  font-family: 'NB Akademie Pro Regular' !important;
  font-weight: 500 !important;
}

@media (--mq-is-mobile) {
  .tabs :global(.MuiTabs-flexContainer) > :global(.MuiTab-root):first-child {
    margin-left: 24px;
  }

  .tabs :global(.MuiTabs-flexContainer) > :global(.MuiTab-root):last-child {
    margin-right: 24px;
  }

  .tabs :global(.MuiTabs-flexContainer):after {
    content: "";
    padding-right: 1px;
  }
}
