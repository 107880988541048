import { Fragment } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ROUTES from 'constants/Routes';
import CSSMargin from 'types/enums/css/Margin';
import { NFTAuthorType, NFTCollaboratorsType } from 'types/graphql/NFT';

import * as styles from 'css/pages/product/ProductDetails.module.css';

interface ProductAuthorDetailsProps {
  author: NFTAuthorType;
  collaborators?: NFTCollaboratorsType;
  collection?: { collectionName: string; slug: string };
  hasBottomMargin?: boolean;
  isGenerative?: boolean;
}

function ProductAuthorDetails({
  author,
  collaborators = [],
  collection,
  hasBottomMargin = true,
  isGenerative = false,
}: ProductAuthorDetailsProps) {
  const otherCollaborators =
    collaborators?.filter((c) => c.user.pk !== author.pk).map((c) => c.user) ||
    [];

  return (
    <div
      className={joinClasses(
        MPFonts.textNormalMedium,
        styles.productAuthorContainer,
        { [CSSMargin.BOTTOM[24]]: hasBottomMargin }
      )}
    >
      {!!collection && (
        <>
          part of the{' '}
          <a
            className="defaultLink"
            href={ROUTES.PROFILE.SHOP(collection.slug)}
          >
            {collection.collectionName}
          </a>{' '}
          {isGenerative ? 'generative ' : ''}collection{' '}
        </>
      )}
      {!otherCollaborators?.length || otherCollaborators.length > 1 ? (
        <>
          by{' '}
          <a
            className="defaultLink"
            href={ROUTES.PROFILE.SHOP(author.store.storeSlug)}
          >
            {author.fullName}
          </a>
          {otherCollaborators.length > 1 ? (
            <> and {otherCollaborators.length} others</>
          ) : null}
        </>
      ) : otherCollaborators.length === 1 ? (
        <>
          by{' '}
          {collaborators.map((collaborator, index) => (
            <Fragment key={collaborator.pk}>
              <a
                className="defaultLink"
                href={ROUTES.PROFILE.SHOP(collaborator.user.store.storeSlug)}
              >
                {collaborator.user.fullName}
              </a>{' '}
              {index < collaborators.length - 1 && <>and </>}
            </Fragment>
          ))}
        </>
      ) : null}
    </div>
  );
}

export default ProductAuthorDetails;
