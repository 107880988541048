import { usePreloadedQuery } from 'react-relay';

import { MPChip, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTsTagAndProvenanceQueryType, {
  NFTsTagAndProvenanceQuery,
} from 'graphql/__generated__/NFTsTagAndProvenanceQuery.graphql';

import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import {
  WithLoadQueryPropsV1,
  withLoadQueryV1,
} from 'utils/hocs/withLoadQuery';

import ProductCollapsibleContainer from './ProductCollapsibleContainer';

import * as styles from '../../css/pages/product/ProductTags.module.css';

function ProductTags({
  queryRef,
}: WithLoadQueryPropsV1<NFTsTagAndProvenanceQuery>) {
  const { nfts } = usePreloadedQuery<NFTsTagAndProvenanceQuery>(
    NFTsTagAndProvenanceQueryType,
    queryRef
  );
  const nft = nfts.edges[0].node;
  const { tags } = nft.metadata;
  return (
    <>
      {tags.length > 0 && (
        <ProductCollapsibleContainer title="Tags">
          <div className={styles.productTags}>
            {tags.map((tag) => (
              <MPChip
                key={tag}
                className={joinClasses(
                  MPFonts.textSmallMedium,
                  styles.productTag
                )}
                variant="outlined"
                label={tag}
              />
            ))}
          </div>
        </ProductCollapsibleContainer>
      )}
    </>
  );
}

export default withDefaultErrorBoundary(
  withLoadQueryV1(ProductTags, NFTsTagAndProvenanceQueryType),
  { hideState: true }
);
