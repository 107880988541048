import { Route } from 'react-router-dom';

import SlashRedirect from 'pages/SlashRedirect';

import EditAccountPage from './editAccount/EditAccountPage';
import StorePage from './StorePage';

const StoreMP1point5Route = (
  <Route path="store" element={<StorePage />}>
    <Route path="edit">
      <Route path="profile" element={<EditAccountPage />} />
      <Route index element={<SlashRedirect />} />
    </Route>
    <Route index element={<SlashRedirect />} />
  </Route>
);

export default StoreMP1point5Route;
