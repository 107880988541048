.walletConnectionModalContent {
  background-color: var(--color-commonWhite);
  box-shadow: 24px;
  left: 50%;
  padding: 1rem;
  border-radius: 4px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}

@media (--mq-is-mobile) {
  .walletConnectionModalContent {
   width: 83vw;
  }
}

.connectButtonContainer {
  margin: 8px 0;
}