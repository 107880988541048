import { WagmiWriteContractManagerProps } from 'types/WagmiWriteContractProps';
import { ContractActionObject, HexString } from 'utils/jwt/walletUtils';

import useCreateContractActionObject from './useCreateContractActionObject';
import useDefaultContractOverrides from './useDefaultContractOverrides';

export default function useTokenContract({
  abi,
  contractAddress,
}: WagmiWriteContractManagerProps) {
  const defaultOverrides = useDefaultContractOverrides();
  const useSafeTransferFrom = ({
    fromAddress,
    toAddress,
    tokenId,
  }: {
    fromAddress: HexString;
    toAddress: HexString;
    tokenId: number;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [fromAddress, toAddress, tokenId],
      functionName: 'safeTransferFrom',
      ...defaultOverrides,
    });

  const useSetApprovalForAll = ({
    operatorAddress,
    approved,
  }: {
    approved: boolean;
    operatorAddress: HexString;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [operatorAddress, approved],
      functionName: 'setApprovalForAll',
      ...defaultOverrides,
    });

  return { useSafeTransferFrom, useSetApprovalForAll };
}
