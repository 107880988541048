/**
 * @generated SignedSource<<b96e14be2d35c6c6bf231bedd804db87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EthereumTransactionStateEnum = "POSTED" | "MINED" | "CANCELLED" | "PENDING" | "FAILED" | "TRYING";
export type NFTTransactionPollingQuery$variables = {
  txtId: string;
};
export type NFTTransactionPollingQuery$data = {
  readonly transactions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly txid: string | null;
        readonly createdAt: any;
        readonly updatedAt: any;
        readonly transactionState: EthereumTransactionStateEnum;
        readonly transactionUrl: string | null;
      } | null;
    } | null>;
  } | null;
};
export type NFTTransactionPollingQuery = {
  variables: NFTTransactionPollingQuery$variables;
  response: NFTTransactionPollingQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "txtId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1
      },
      {
        "kind": "Variable",
        "name": "txtId",
        "variableName": "txtId"
      }
    ],
    "concreteType": "TransactionAbstractTypeConnection",
    "kind": "LinkedField",
    "name": "transactions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TransactionAbstractTypeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TransactionAbstractType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pk",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "txid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transactionState",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transactionUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTTransactionPollingQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NFTTransactionPollingQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b36378dab086cb655358e48461ceb88e",
    "id": null,
    "metadata": {},
    "name": "NFTTransactionPollingQuery",
    "operationKind": "query",
    "text": "query NFTTransactionPollingQuery(\n  $txtId: String!\n) {\n  transactions(txtId: $txtId, first: 1) {\n    edges {\n      node {\n        id\n        pk\n        txid\n        createdAt\n        updatedAt\n        transactionState\n        transactionUrl\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "01955e864d1291916df6b74a940fc908";

export default node;
