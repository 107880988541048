import Box from '@mui/material/Box';

export default function MPShadowBox({ ...passedProps }) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        bgcolor: 'background.paper',
        boxShadow: 8,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '15rem',
        width: '100%',
      }}
      {...passedProps}
    />
  );
}
