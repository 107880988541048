import { MPFonts, MPShadowBox } from '@mp-frontend/core-components';

import StoreLayout from '../StoreLayout';

function LiveOffersPage() {
  return (
    <StoreLayout>
      <h2 className={MPFonts.titleMedium}>Live Offers</h2>

      <br />
      <br />

      <MPShadowBox>
        <p className={MPFonts.bodyLarge}>
          You currently don&#39;t have any live offers.
        </p>
      </MPShadowBox>
    </StoreLayout>
  );
}

export default LiveOffersPage;
