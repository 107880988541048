.marginLeft2 {
  margin-left: 2px;
}

.marginLeft16 {
  margin-left: 16px;
}

.marginBottom6 {
  margin-bottom: 6px;
}

.marginBottom8 {
  margin-bottom: 8px;
}

.marginBottom24 {
  margin-bottom: 24px;
}

.marginBottom32 {
  margin-bottom: 32px;
}

.marginRight4 {
  margin-right: 4px;
}

.marginTop16 {
  margin-top: 16px;
}

.marginTop24 {
  margin-top: 24px;
}

.marginTop32 {
  margin-top: 32px;
}
