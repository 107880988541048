import { Outlet } from 'react-router-dom';
import { useGateValue } from '@statsig/react-bindings';

import DefaultSuspense from 'components/DefaultSuspense';
import SlashRedirect from 'pages/SlashRedirect';

import SideNavBar from './SideNavBar';

import * as styles from 'css/pages/store/StorePage.module.css';

function StorePage() {
  const canShow = useGateValue('gate_mp2_settings');
  const canShowMP1point5 = useGateValue('gate_mp1point5_settings');
  if (!canShow && !canShowMP1point5) return <SlashRedirect />;

  return (
    <div className={styles.storePageContent}>
      {!!canShow && <SideNavBar />}
      <div className={styles.storePageContentRight}>
        <DefaultSuspense>
          <Outlet />
        </DefaultSuspense>
      </div>
    </div>
  );
}

export default StorePage;
