import { KeyboardEvent, useCallback } from 'react';

export default function useOnEnterKey(callback) {
  return useCallback(
    (e: KeyboardEvent) => {
      if (['Enter', 'NumpadEnter'].includes(e.key)) {
        e.preventDefault(); // Ensure it is only this code that runs
        callback(e);
      }
    },
    [callback]
  );
}
