.outerContainer {
  background-color: var(--color-commonBlack);
}

.container {
  margin: 0 auto;
  max-width: 1920px;
  padding: 60px 32px 128px 32px;
  position: relative;

  @media (--mq-is-mobile) {
    padding: 60px 26px 64px 26px;
  }
}


.header {
  color: var(--color-commonWhite);
  margin-bottom: 22px;

  @media (--mq-is-mobile) {
    margin-bottom: 20px;
  }
}

.list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  list-style-type: none;
  max-width: 100%;
  overflow: hidden;
  padding: 0;

  @media screen and (min-width: 1920px) {
    gap: 8px;
  }

  @media (--mq-is-mobile) {
    flex-direction: column;
    gap: 6px;
  }
}

/*TODO: Brian will add this breakpoint */
@media screen and (min-width: 1920px) {
  .container {
    padding: 60px 112px 128px 112px;
    max-width: 1696px;
  }
}
