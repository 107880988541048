.productTags {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}

.productTag {
  border: 1px solid var(--color-commonBlack);
  border-radius: 16px;
  height: 29px;
}
