import { Link } from 'react-router-dom';

import ROUTES from 'constants/Routes';
import useSession from 'hooks/useSession';

import * as styles from 'css/pages/store/SideNavBar.module.css';

function SideNavBar() {
  const session = useSession();
  return (
    <nav className={styles.sideNav}>
      <ul className={styles.navList}>
        <li>
          <div className={styles.logo}>
            <Link to={session.account.landingUrl}>
              <img
                src={session.account.profileImageUrl}
                className={styles.profileImage}
              />
            </Link>
          </div>
        </li>
        {session.isCreator() && (
          <li className={styles.navItem}>
            <Link to={ROUTES.STORE.CREATIONS()}>Your Creations</Link>
          </li>
        )}
        <li className={styles.navItem}>
          <Link to={ROUTES.STORE.PRODUCTS()}>On Sale</Link>
        </li>
        <li className={styles.navItem}>
          <Link to={ROUTES.STORE.LIVE_OFFERS()}>Live Offers</Link>
        </li>
        <li className={styles.navItem}>
          <Link to={ROUTES.STORE.PURCHASE_HISTORY()}>Sale History</Link>
        </li>
        {session.isCreator() && (
          <li className={styles.navItem}>
            <Link to={ROUTES.STORE.ROYALTY_HISTORY()}>Royalties</Link>
          </li>
        )}
        <li className={styles.navItem}>
          <Link to={ROUTES.STORE.GIFTS()}>Send Creations</Link>
        </li>
        {session.isCreator() && (
          <>
            <li className={styles.navItem}>
              <Link to={ROUTES.STORE.REACTIONS()}>Recent Activity</Link>
            </li>
            <li className={styles.navItem}>
              <Link to={ROUTES.STORE.TRANSACTION_HISTORY()}>
                Blockchain Fees
              </Link>
            </li>
          </>
        )}
        <li className={styles.navItem}>
          <div className={styles.seperator} />
        </li>
        <li className={styles.navItem}>
          <Link to={ROUTES.STORE.EDIT_STORE()}>
            {session.isCreator() ? 'Store Settings' : 'Payment Settings'}
          </Link>
        </li>

        <li className={styles.navItem}>
          <Link to={ROUTES.STORE.EDIT_ACCOUNT()}>Account Settings</Link>
        </li>
      </ul>
    </nav>
  );
}

export default SideNavBar;
