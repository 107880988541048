import { Link } from 'react-router-dom';

import { joinClasses } from '@mp-frontend/core-utils';

import ROUTES from 'constants/Routes';
import useSession from 'hooks/useSession';

import * as styles from 'css/pages/store/SideNavBar1point5.module.css';

function SideNavBar1point5() {
  const session = useSession();
  const profileImageUrl = session.account.profileImageUrl.replace(
    '/default-profile.png',
    '/default-logo.png'
  );
  return (
    <nav className={styles.sideNav}>
      <ul className={styles.navList}>
        <li>
          <div className={styles.logo}>
            <div className={styles.logoBorder}>
              <Link
                to={
                  session.isCreator()
                    ? ROUTES.STORE.EDIT()
                    : ROUTES.STORE.EDIT.PROFILE()
                }
                reloadDocument
              >
                <img src={profileImageUrl} className={styles.profileImage} />
              </Link>
            </div>
          </div>
        </li>
        {session.isCreator() && (
          <li className={styles.navItem}>
            <Link to={ROUTES.STORE.CREATIONS()} reloadDocument>
              Your Creations
            </Link>
          </li>
        )}
        <li className={styles.navItem}>
          <Link to={ROUTES.STORE.PRODUCTS()} reloadDocument>
            On Sale
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link to={ROUTES.STORE.LIVE_OFFERS()} reloadDocument>
            Live Offers
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link to={ROUTES.STORE.PURCHASE_HISTORY()} reloadDocument>
            Sale History
          </Link>
        </li>
        {session.isCreator() && (
          <li className={styles.navItem}>
            <Link to={ROUTES.STORE.ROYALTY_HISTORY()} reloadDocument>
              Royalties
            </Link>
          </li>
        )}
        <li className={styles.navItem}>
          <Link to={ROUTES.STORE.GIFTS()} reloadDocument>
            Send Creations
          </Link>
        </li>
        {session.isCreator() && (
          <>
            <li className={styles.navItem}>
              <Link to={ROUTES.STORE.REACTIONS()} reloadDocument>
                Recent Activity
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link to={ROUTES.STORE.TRANSACTION_HISTORY()} reloadDocument>
                Blockchain Fees
              </Link>
            </li>
          </>
        )}
        <li className={styles.navItem}>
          <div className={styles.seperator} />
        </li>
        <li className={styles.navItem}>
          <Link to="/store/edit/about/" reloadDocument>
            About
          </Link>
        </li>

        <li className={styles.navItem}>
          <Link to={ROUTES.STORE.EDIT()} reloadDocument>
            {session.isCreator() ? 'Store Settings' : 'Payment Settings'}
          </Link>
        </li>

        <li className={joinClasses(styles.navItem, styles.navItemActive)}>
          <Link to={ROUTES.STORE.EDIT_ACCOUNT()}>Account Settings</Link>
        </li>

        <li className={joinClasses(styles.navItem, styles.navItemBold)}>
          <Link to={session.account.landingUrl} target="_BLANK">
            {session.isCreator() ? 'View Your Store' : 'View Your Collection'}
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default SideNavBar1point5;
