import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  MPActionButton,
  MPDialog,
  MPFormTextInput,
  useIsMobile,
} from '@mp-frontend/core-components';

import useSession from 'hooks/useSession';
import useSimpleDialogController from 'hooks/useSimpleDialogController';

import TwoFactorDialog from './TwoFactorDialog';

import * as styles from 'css/pages/store/editAccount/TwoFactorDialog.module.css';

interface TwoFactorCollectPhoneDialogProps {
  cancel: () => void;
  isOpen: boolean;
  setSendCodeError: Dispatch<SetStateAction<string>>;
}

function TwoFactorCollectPhoneDialog({
  cancel,
  isOpen,
  setSendCodeError,
}: TwoFactorCollectPhoneDialogProps) {
  const session = useSession();
  const isMobile = useIsMobile();

  const [errorMessage, setErrorMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [isTwoFactorDialogOpen, openTwoFactorDialog, closeTwoFactorDialog] =
    useSimpleDialogController({ preventDefault: true });

  // Don't ask for a phone number if the account already has one.
  useEffect(() => {
    if (isOpen && session.account.phoneNumber) {
      cancel();
      openTwoFactorDialog();
    }
  }, [cancel, isOpen, openTwoFactorDialog, session.account.phoneNumber]);

  if (session.account.phoneNumber) {
    return (
      <TwoFactorDialog
        channel="sms"
        isOpen={isTwoFactorDialogOpen}
        cancel={closeTwoFactorDialog}
        setSendCodeError={setSendCodeError}
        phoneNumber={session.account.phoneNumber}
      />
    );
  }

  const handlePhoneNumberChange = (event) => setPhoneNumber(event.target.value);

  const submitButton = (
    <MPActionButton
      onClick={openTwoFactorDialog}
      disabled={isTwoFactorDialogOpen}
    >
      Confirm
    </MPActionButton>
  );

  return (
    <>
      <MPDialog
        onClose={cancel}
        open={isOpen}
        actionButton={submitButton}
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: 'var(--maxDialogHeight)',
            width: isMobile ? '100%' : '500px',
          },
        }}
        title="Enter Your Mobile Number"
      >
        <div className={styles.dialogContents}>
          <p>
            You will receive a one-time secure code to enable 2FA on your mobile
            device.
          </p>
          <MPFormTextInput
            variant="outlined"
            required
            fullWidth
            error={!!errorMessage}
            value={phoneNumber}
            onBlur={handlePhoneNumberChange}
            onChange={handlePhoneNumberChange}
          />

          {!!errorMessage && (
            <div className={styles.codeInputError}>{errorMessage}</div>
          )}
        </div>
      </MPDialog>

      {/* TODO: change from dialogs to inline forms */}
      <TwoFactorDialog
        channel="sms"
        isOpen={isTwoFactorDialogOpen}
        cancel={closeTwoFactorDialog}
        onSendCodeSuccess={cancel}
        setSendCodeError={setErrorMessage}
        phoneNumber={phoneNumber}
      />
    </>
  );
}

export default TwoFactorCollectPhoneDialog;
