import { MouseEvent, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NFTLikeMutation } from 'graphql/__generated__/NFTLikeMutation.graphql';

import { useTrackingContext } from 'components/trackingContext';
import ROUTES from 'constants/Routes';
import useSession from 'hooks/useSession';
import useToggleLikeMutation from 'hooks/useToggleLikeMutation';
import { NFTType } from 'types/graphql/NFT';
import useOnEnterKey from 'utils/eventHandlers/useOnEnterKey';

export default function useLikeProduct({ nft }: { nft: NFTType }) {
  const { pk, metadata } = nft;

  const session = useSession();
  const location = useLocation();
  const navigate = useNavigate();
  const [likesCount, setLikesCount] = useState<number>(metadata.likesCount);
  const [didUserLike, setDidUserLike] = useState<boolean>(
    metadata.currentAccountLike
  );
  const [nftLikeMutation, isLoading] = useToggleLikeMutation(didUserLike);
  const { carouselTitle = '' } = useTrackingContext();

  const handleMouseEvent = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      if (!session.account) {
        navigate(ROUTES.LOGIN.LOGIN_NEXT(location.pathname));
        return;
      }

      nftLikeMutation(
        {
          onCompleted({
            likeNft: { success, liked, likesCount: newLikesCount },
          }: NFTLikeMutation['response']) {
            if (!success) {
              return;
            }

            setDidUserLike(liked);
            setLikesCount(newLikesCount);
          },
          variables: { nftId: parseInt(pk, 10) },
        },
        {
          carousel: carouselTitle,
        }
      );
    },
    [isLoading, nftLikeMutation, nft, session.account] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleKeyboardEvent = useOnEnterKey(handleMouseEvent);

  return [
    likesCount,
    didUserLike,
    handleMouseEvent,
    handleKeyboardEvent,
  ] as const;
}
