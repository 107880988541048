import Grid from '@mui/material/Grid';

import * as styles from 'css/pages/store/StoreLayout.module.css';

export default function StoreLayout({ children }) {
  return (
    <div className={styles.storeLayoutBody}>
      <Grid container className={styles.storeLayoutGrid}>
        <Grid item mobile={11} desktop={8} wide={8} xwide={6}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
}
