import { Connector, useConnect } from 'wagmi';
import { Modal } from '@mui/material';

import {
  MPActionButton,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import useConnectorStatus from 'hooks/wallet/useConnectorStatus';
import {
  ConnectorName,
  openMetaMaskDeepLink,
  PendingConnectionStatus,
} from 'utils/jwt/walletUtils';

import * as styles from '../css/components/WalletConnectionModal.module.css';

export interface WalletConnectionModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onConnection?: (connection) => any;
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
}

const defaultConnectionCallback = () => true;

const transactionStatusDescriptionMapping = {
  [PendingConnectionStatus.Idle]: 'not connected',
  [PendingConnectionStatus.Loading]: 'connecting',
  [PendingConnectionStatus.Error]: 'error',
  [PendingConnectionStatus.Success]: 'connected',
};

/**
 * @Deprecated
 *
 * This code should be replaced by the Settings 2.0, MP3.0, whatever it is we're calling it
 * @returns
 */
export default function WalletConnectionModal({
  isOpen,
  onConnection,
  onClose = defaultConnectionCallback,
  onError = (error) => error,
  onMutate = defaultConnectionCallback,
  onSettled = defaultConnectionCallback,
  onSuccess = defaultConnectionCallback,
}: WalletConnectionModalProps) {
  const isMobile = useIsMobile();
  const {
    connectAsync,
    connectors,
    error: walletConnectionError,
    isPending: isWalletConnectionLoading,
    status,
  } = useConnect({
    mutation: {
      onError,
      onMutate,
      onSettled,
      onSuccess,
    },
  });

  const connectWithEthereum = (connector: Connector) => {
    try {
      const connection = connectAsync({ connector });
      onConnection(connection);
    } catch (error) {
      onError(error);
    }
  };

  const connectorStatus = useConnectorStatus(connectors);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: isMobile ? '100vh' : '85vh',
          width: isMobile ? '100%' : '30%',
        },
      }}
    >
      <div className={styles.walletConnectionModalContent}>
        <h5 className={MPFonts.titleSmall}>Connect your Wallet</h5>

        <p className={MPFonts.bodySmall}>
          Connection status: {transactionStatusDescriptionMapping[status]}
        </p>

        {!!walletConnectionError && (
          <p className={joinClasses(MPFonts.bodySmall, 'colorErrorMain')}>
            Error: &quot;{walletConnectionError.message}&quot;
          </p>
        )}

        <div>
          {connectors.map((connector) => {
            if (
              connector.name === ConnectorName.MetaMask &&
              !connectorStatus[connector.id]
            ) {
              return (
                <div
                  className={styles.connectButtonContainer}
                  key={connector.id}
                >
                  <MPActionButton fullWidth onClick={openMetaMaskDeepLink}>
                    Metamask
                  </MPActionButton>
                </div>
              );
            }

            if (
              connector.name === ConnectorName.Injected &&
              !connectorStatus[connector.id]
            )
              return '';

            return (
              <div className={styles.connectButtonContainer} key={connector.id}>
                <MPActionButton
                  disabled={!connectorStatus[connector.id]}
                  fullWidth
                  onClick={() => connectWithEthereum(connector)}
                >
                  {connector.name !== 'Injected' ? connector.name : 'Other'}
                  {!connectorStatus[connector.id] && ' (unsupported)'}
                  {/* eslint-disable-next-line react/jsx-no-leaked-render */}
                  {isWalletConnectionLoading && ' (connecting)'}
                </MPActionButton>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}
