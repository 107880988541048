import { usePreloadedQuery } from 'react-relay';
import { Grid } from '@mui/material';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountCreatorContractsQueryType, {
  AccountCreatorContractsQuery,
} from 'graphql/__generated__/AccountCreatorContractsQuery.graphql';

import WalletActionButton from 'components/WalletActionButton';
import useApprovedCreatorRegistryContract from 'hooks/contracts/useApprovedCreatorRegistryContract';
import useManifoldContract from 'hooks/contracts/useManifoldContract';
import useTokenContract from 'hooks/contracts/useTokenContract';
import useRegisterWallet from 'hooks/useRegisterWallet';
import useSession from 'hooks/useSession';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { HexString } from 'utils/jwt/walletUtils';

interface ContractProps {
  abidata: string;
  address: string;
}

interface ContractApprovalsProps {
  contract: ContractProps & {
    approvedCreatorRegistryAbidata: string;
    approvedCreatorRegistryAddress: string;
    digitalMediaSaleCoreAddress: string;
    dropSaleCoreAddress: string;
    hasApprovedCreatorRegistryApproval: boolean;
    hasManifoldAdminApproval: boolean;
    hasTransferCoreApproval: boolean;
    hasVaultCoreApproval: boolean;
    isManifold: boolean;
    manifoldAdminAddress: string;
    transferCoreAddress: string;
    vaultCoreAddress: string;
  };
}

function ManifoldAdminApproval({ contract }: ContractApprovalsProps) {
  const { useApproveAdmin, useRevokeAdmin } = useManifoldContract({
    abi: JSON.parse(contract.abidata).abi,
    contractAddress: contract.address as HexString,
  });

  const approveAdmin = useApproveAdmin({
    adminAddress: contract.manifoldAdminAddress as HexString,
  });
  const revokeAdmin = useRevokeAdmin({
    adminAddress: contract.manifoldAdminAddress as HexString,
  });

  const toggleApproval = () => {
    (contract.hasManifoldAdminApproval
      ? revokeAdmin
      : approveAdmin
    ).mutate.writeAsync();
  };

  return (
    <WalletActionButton size="small" onClick={toggleApproval}>
      {contract.hasManifoldAdminApproval ? 'Revoke' : 'Approve'}
    </WalletActionButton>
  );
}

function ContractApprovals({ contract }: ContractApprovalsProps) {
  const { useSetApprovalForAll } = useTokenContract({
    abi: JSON.parse(contract.abidata).abi,
    contractAddress: contract.address as HexString,
  });
  const registryApprovalManager = useSetApprovalForAll({
    approved: !contract.hasApprovedCreatorRegistryApproval,
    operatorAddress: contract.approvedCreatorRegistryAddress as HexString,
  });
  const transferApprovalManager = useSetApprovalForAll({
    approved: !contract.hasTransferCoreApproval,
    operatorAddress: contract.transferCoreAddress as HexString,
  });
  const vaultApprovalManager = useSetApprovalForAll({
    approved: !contract.hasVaultCoreApproval,
    operatorAddress: contract.vaultCoreAddress as HexString,
  });

  const toggleApproval = (tokenContractManager) => {
    tokenContractManager.contractWrite.writeAsync();
  };

  return (
    <div>
      <p>
        Contract approvals for {contract.address}:
        <ul>
          <li>
            ApprovedCreatorRegistry:&nbsp;
            <WalletActionButton
              size="small"
              onClick={() => toggleApproval(registryApprovalManager)}
            >
              {contract.hasApprovedCreatorRegistryApproval
                ? 'Revoke'
                : 'Approve'}
            </WalletActionButton>
          </li>
          <li>
            TransferCore:&nbsp;
            <WalletActionButton
              size="small"
              onClick={() => toggleApproval(transferApprovalManager)}
            >
              {contract.hasTransferCoreApproval ? 'Revoke' : 'Approve'}
            </WalletActionButton>
          </li>
          <li>
            VaultCore:&nbsp;
            <WalletActionButton
              size="small"
              onClick={() => toggleApproval(vaultApprovalManager)}
            >
              {contract.hasVaultCoreApproval ? 'Revoke' : 'Approve'}
            </WalletActionButton>
          </li>
          {!!contract.isManifold && (
            <li>
              Manifold Admin:&nbsp;
              <ManifoldAdminApproval contract={contract} />
            </li>
          )}
        </ul>
      </p>
    </div>
  );
}

interface ApprovedCreatorRegistryOboApprovalProps {
  approvedCreatorRegistryAbidata: string;
  approvedCreatorRegistryAddress: string;
  hasApprovedCreatorRegistryOboApproval: boolean;
  operatorAddress: string;
}

function ApprovedCreatorRegistryOboApproval({
  approvedCreatorRegistryAbidata,
  approvedCreatorRegistryAddress,
  hasApprovedCreatorRegistryOboApproval,
  operatorAddress,
}: ApprovedCreatorRegistryOboApprovalProps) {
  const { useSetOboApprovalForAll } = useApprovedCreatorRegistryContract({
    abi: JSON.parse(approvedCreatorRegistryAbidata).abi,
    contractAddress: approvedCreatorRegistryAddress as HexString,
  });
  const registryOboApprovalManager = useSetOboApprovalForAll({
    isApproved: !hasApprovedCreatorRegistryOboApproval,
    operatorAddress: operatorAddress as HexString,
  });

  const toggleApproval = () => {
    registryOboApprovalManager.mutate.writeAsync();
  };

  return (
    <WalletActionButton size="small" onClick={toggleApproval}>
      {hasApprovedCreatorRegistryOboApproval ? 'Revoke' : 'Approve'}
    </WalletActionButton>
  );
}

function WalletsAndApprovalsPage({
  creatorContractsQuery,
}: {
  creatorContractsQuery: WithLoadQueryProps<AccountCreatorContractsQuery>;
}) {
  const session = useSession();
  const { creatorContracts } = usePreloadedQuery(
    AccountCreatorContractsQueryType,
    creatorContractsQuery.queryRef
  );
  const { wallets } = session.account || {};
  const [anyContract] = creatorContracts;
  const [mintingWallet] = wallets.filter((w) => w.isSelectedMintingWallet);

  const { error, isError, isLoading, registerWallet } = useRegisterWallet();

  return (
    <Grid container>
      <Grid item mobile={1} desktop={4} wide={4} xwide={5} />
      <Grid item mobile={10} desktop={4} wide={4} xwide={2}>
        <h2 className={MPFonts.titleMedium}>Wallets and Approvals</h2>

        <h3 className={MPFonts.headline3}>Wallets</h3>

        {wallets.map(
          (wallet) =>
            !wallet.isCustodialCreatorWallet && (
              <div key={wallet.address}>
                <p>
                  Personal wallet
                  {wallet.isCreatorWallet
                    ? wallet.isSelectedMintingWallet
                      ? ' (selected for minting)'
                      : ' (eligible for minting)'
                    : ''}
                  : {wallet.address}
                </p>
              </div>
            )
        )}
        {/* TODO: button for each personal wallet to disconnect it (low priority) */}
        {wallets.map(
          (wallet) =>
            !!wallet.isCustodialCreatorWallet && (
              <div key={wallet.address}>
                <p>
                  Custodial minting wallet
                  {wallet.isSelectedMintingWallet
                    ? ' (selected for minting)'
                    : ' (historical only)'}
                  : {wallet.address}
                </p>
              </div>
            )
        )}
        <div>
          {!!isError && (
            <div className={joinClasses('errorMsgColor', 'defaultTextMargin')}>
              {error?.message ??
                'Something went wrong, please try again later.'}
            </div>
          )}
          <WalletActionButton
            type="button"
            size="large"
            onClick={() => registerWallet(false)}
            isLoading={isLoading}
          >
            Connect a Wallet
          </WalletActionButton>
        </div>

        <br />
        <br />

        {!!mintingWallet && !mintingWallet.isCustodialCreatorWallet && (
          <>
            <h3 className={MPFonts.headline3}>Contracts</h3>

            <p>For the minting wallet {mintingWallet.address}</p>

            <h4 className={MPFonts.headline4}>Token Contracts</h4>

            {creatorContracts.map((contract) => (
              <ContractApprovals key={contract.address} contract={contract} />
            ))}

            {!!anyContract && (
              <>
                <h4 className={MPFonts.headline4}>Sales Contracts</h4>
                <p>
                  DigitalMediaSaleCore:&nbsp;
                  <ApprovedCreatorRegistryOboApproval
                    approvedCreatorRegistryAbidata={
                      anyContract.approvedCreatorRegistryAbidata
                    }
                    approvedCreatorRegistryAddress={
                      anyContract.approvedCreatorRegistryAddress
                    }
                    hasApprovedCreatorRegistryOboApproval={
                      anyContract.hasOboApprovalForDigitalMediaSaleCore
                    }
                    operatorAddress={anyContract.digitalMediaSaleCoreAddress}
                  />
                </p>
                <p>
                  DropSaleCore:&nbsp;
                  <ApprovedCreatorRegistryOboApproval
                    approvedCreatorRegistryAbidata={
                      anyContract.approvedCreatorRegistryAbidata
                    }
                    approvedCreatorRegistryAddress={
                      anyContract.approvedCreatorRegistryAddress
                    }
                    hasApprovedCreatorRegistryOboApproval={
                      anyContract.hasOboApprovalForDropSaleCore
                    }
                    operatorAddress={anyContract.dropSaleCoreAddress}
                  />
                </p>
              </>
            )}
          </>
        )}
      </Grid>
      <Grid item mobile={1} desktop={4} wide={4} xwide={5} />
    </Grid>
  );
}

export default withLoadQuery(WalletsAndApprovalsPage, {
  creatorContractsQuery: { concreteRequest: AccountCreatorContractsQueryType },
});
