.outerContainer {
  background-color: var(--color-commonBlack);
  border-top: 0.5px solid var(--color-SolidNeutralGray7);
}

.container {
  color: var(--color-commonWhite);
  display: grid;
  grid-template-areas:
    "logo-and-copyright help company social"
    "logo-and-copyright help company social";
  grid-template-columns: 1fr minmax(min-content, 200px) minmax(min-content, 200px) minmax(min-content, 200px);
  grid-template-rows: 1fr auto;
  column-gap: 32px;
  padding: 64px 32px;

  @media (--mq-is-mobile) {
    grid-template-areas:
      "help company"
      "social social"
      "logo-and-copyright logo-and-copyright";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 32px;
    padding: 64px 24px 32px 24px;
  }
}

.container > ul {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;

  &[title]::before {
    color: var(--color-grey600);
    content: attr(title);
    display: block;
    font-family: var(--font-medium);
    font-size: 14px;
    line-height: 1;
    margin-bottom: 8px;
  }
}

.container > ul > li {
  cursor: pointer;
  padding: 2px 0px 0px;
}

.container > ul > li > a {
  text-decoration: none;
  color: var(--color-commonWhite);
}

.logoAndCopyright {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-area: logo-and-copyright;
  justify-content: space-between;

  @media (--mq-is-mobile) {
    margin-top: 31px;
    gap: 16px;
  }
}

.logo {
  display: inline-block;
}

.copyright {
  color: var(--color-grey600);
  cursor: default;
}

.help {
  grid-area: help;
}

.company {
  grid-area: company;
}

.social {
  grid-area: social;
}

/*TODO: Brian will add this breakpoint */
@media screen and (min-width: 1920px) {
  .container {
    padding: 64px 112px;
    max-width: 1696px;
    margin: auto;
  }
}