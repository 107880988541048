import * as styles from 'css/global/Gap.module.css';

const CSSGap = {
  '10': styles.gap10,
  '12': styles.gap12,
  '16': styles.gap16,
  '18': styles.gap18,
  '2': styles.gap2,
  '20': styles.gap20,
  '24': styles.gap24,
  '28': styles.gap28,
  '32': styles.gap32,
  '4': styles.gap4,
  '6': styles.gap6,
  '8': styles.gap8,
} as const;

export default CSSGap;
