import { MPDivider } from '@mp-frontend/core-components';

import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';

import useProductOwnership from 'hooks/product/useProductOwnership';
import useSession from 'hooks/useSession';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTType } from 'types/graphql/NFT';
import { getExistingBid } from 'utils/nftUtils';

import ProductAcceptOrRejectBid from './owner/ProductAcceptOrRejectBid';
import ProductBadges, { useBadges } from './ProductBadges';

import * as styles from 'css/pages/product/ProductDetails.module.css';

export interface ProductTopSectionProps {
  approvalRegistryContract: NFTContractQuery$data['nftContract'];
  invalidate: () => void;
  nft: NFTType;
  transferCoreContract: NFTContractQuery$data['nftContract'];
}

export default function ProductTopSection({
  nft,
  invalidate,
  approvalRegistryContract,
  transferCoreContract,
}: ProductTopSectionProps) {
  const { hasBadges } = useBadges({
    nft,
    nftMetadata: nft.metadata,
  });
  const currencyDisplay = nft.listing.liveBid?.isCcBid
    ? CurrencyDisplayMode.USD
    : CurrencyDisplayMode.ETH;
  const session = useSession();
  const isCurrentUserTokenOwner = useProductOwnership({ nft });
  const showBidAccept =
    getExistingBid(nft, currencyDisplay) > 0 &&
    isCurrentUserTokenOwner &&
    nft.onchainId &&
    session.isLoggedIn();

  return (
    <>
      {!!showBidAccept && (
        <ProductAcceptOrRejectBid
          nft={nft}
          invalidate={invalidate}
          approvalRegistryContract={approvalRegistryContract}
          transferCoreContract={transferCoreContract}
        />
      )}
      {!!hasBadges && (
        <div className={styles.productBadgeContainer}>
          <div className={styles.productBadgeContainerText}>
            <ProductBadges nft={nft} nftMetadata={nft.metadata} />
          </div>
          <MPDivider />
        </div>
      )}
    </>
  );
}
