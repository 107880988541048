/**
 * @generated SignedSource<<0ece037e3d1b75ac36da979a8303c377>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HomepageGenresQuery$variables = {};
export type HomepageGenresQuery$data = {
  readonly homepageGenres: ReadonlyArray<{
    readonly title: string | null;
    readonly tag: string | null;
    readonly mediaUrl: string | null;
    readonly mediaArtist: string | null;
    readonly mediaTitle: string | null;
  } | null> | null;
};
export type HomepageGenresQuery = {
  variables: HomepageGenresQuery$variables;
  response: HomepageGenresQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GenreType",
    "kind": "LinkedField",
    "name": "homepageGenres",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tag",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mediaUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mediaArtist",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mediaTitle",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomepageGenresQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomepageGenresQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f10c2826fec1bbea5a7e09dbcb74cfda",
    "id": null,
    "metadata": {},
    "name": "HomepageGenresQuery",
    "operationKind": "query",
    "text": "query HomepageGenresQuery {\n  homepageGenres {\n    title\n    tag\n    mediaUrl\n    mediaArtist\n    mediaTitle\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6aacdb129e67c9dfcbbc2a0572fd378";

export default node;
