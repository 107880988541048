import { useRef } from 'react';
import {
  ErrorMessage,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { noop } from 'lodash';
import { useMutation } from 'react-relay';
import * as Yup from 'yup';

import {
  MPActionButton,
  MPDialog,
  MPFormTextInput,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountChangePassword, {
  AccountChangePasswordMutation,
} from 'graphql/__generated__/AccountChangePasswordMutation.graphql';
import { ChangePasswordArguments, MpErrors } from 'types/__generated__/graphql';

import saveBearerToken from 'utils/jwt/saveBearerToken';

import * as inputStyles from 'css/global/Inputs.module.css';
import * as styles from 'css/pages/store/editAccount/ChangePasswordDialog.module.css';

interface ChangePasswordDialogProps {
  cancel: () => void;
  isOpen: boolean;
}

const defaultFormikProps = {
  handleSubmit: noop,
  isSubmitting: false,
};

const ChangePasswordSchema = Yup.object().shape({
  confirmNewPassword: Yup.string()
    .required('Required')
    .when(['newPassword'], (newPassword, schema) =>
      schema.test({
        message: 'Passwords must match.',
        name: 'matches',
        test: (value) => value === newPassword,
      })
    ),
  newPassword: Yup.string()
    .required('Required')
    /* eslint-disable-next-line no-template-curly-in-string */
    .min(8, 'Password must be at least ${min} characters')
    /* eslint-disable-next-line no-template-curly-in-string */
    .max(128, 'Password must be at most ${max} characters'),
  oldPassword: Yup.string().required('Required'),
});

function validateForm(values) {
  try {
    validateYupSchema(values, ChangePasswordSchema, true);
  } catch (err) {
    return yupToFormErrors(err);
  }
  return undefined;
}

function ChangePasswordDialog({ isOpen, cancel }: ChangePasswordDialogProps) {
  const [changePassword, isChangingPassword] =
    useMutation<AccountChangePasswordMutation>(AccountChangePassword);

  const formikPropsRef = useRef(defaultFormikProps);

  const submitButton = (
    <MPActionButton
      onClick={formikPropsRef.current.handleSubmit}
      disabled={
        isChangingPassword
        // TODO: figure out how to get the form props passed up here.
        // validateForm(formikPropsRef.current.values) !== undefined
      }
    >
      Change password
    </MPActionButton>
  );

  const isMobile = useIsMobile();

  return (
    <MPDialog
      onClose={cancel}
      open={isOpen}
      actionButton={submitButton}
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: 'var(--maxDialogHeight)',
          width: isMobile ? '100%' : '700px',
        },
      }}
      title="Change Password"
    >
      <Formik
        initialValues={{
          confirmNewPassword: '',
          newPassword: '',
          oldPassword: '',
        }}
        validate={validateForm}
        enableReinitialize
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          if (isChangingPassword) return;
          const variables: ChangePasswordArguments = {
            newPassword: values.newPassword,
            oldPassword: values.oldPassword,
          };

          changePassword({
            onCompleted: (result) => {
              setSubmitting(false);
              cancel();
              saveBearerToken(result.changePassword.token);
            },
            onError: (error) => {
              if (
                error.name === MpErrors.LoginNotValid ||
                error.name === MpErrors.NotActiveUser
              ) {
                setFieldError('oldPassword', error.message);
              } else {
                setFieldError('newPassword', error.message);
              }
            },
            variables,
          });
          setSubmitting(true);
        }}
      >
        {(props) => {
          formikPropsRef.current = props;

          return (
            <form>
              <div className={styles.dialogPadding}>
                <div className={styles.changePasswordDiv}>
                  <MPFormTextInput
                    label="Old Password"
                    name="oldPassword"
                    type="password"
                    variant="outlined"
                    required
                    fullWidth
                    className={styles.changePasswordTextInput}
                    value={props.values.oldPassword}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                  <ErrorMessage name="oldPassword">
                    {(msg) => (
                      <div
                        className={joinClasses(
                          inputStyles.errorMessage,
                          styles.changePasswordErrorMessage
                        )}
                      >
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <div className={styles.changePasswordDiv}>
                  <MPFormTextInput
                    label="New Password"
                    name="newPassword"
                    type="password"
                    variant="outlined"
                    required
                    fullWidth
                    className={styles.changePasswordTextInput}
                    value={props.values.newPassword}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                  <ErrorMessage name="newPassword">
                    {(msg) => (
                      <div
                        className={joinClasses(
                          inputStyles.errorMessage,
                          styles.changePasswordErrorMessage
                        )}
                      >
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <div className={styles.changePasswordDiv}>
                  <MPFormTextInput
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    type="password"
                    variant="outlined"
                    required
                    fullWidth
                    className={styles.changePasswordTextInput}
                    value={props.values.confirmNewPassword}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                  <ErrorMessage name="confirmNewPassword">
                    {(msg) => (
                      <div
                        className={joinClasses(
                          inputStyles.errorMessage,
                          styles.changePasswordErrorMessage
                        )}
                      >
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </MPDialog>
  );
}

export default ChangePasswordDialog;
