/**
 * @generated SignedSource<<0e1e321bc83acfb7e3b9c604c60fc636>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountChangeFullNameAndEmailMutation$variables = {
  email?: string | null;
  username?: string | null;
  fullName?: string | null;
};
export type AccountChangeFullNameAndEmailMutation$data = {
  readonly changeFullNameAndEmail: {
    readonly success: boolean | null;
    readonly account: {
      readonly email: string;
      readonly username: string;
      readonly fullName: string | null;
    } | null;
  } | null;
};
export type AccountChangeFullNameAndEmailMutation = {
  variables: AccountChangeFullNameAndEmailMutation$variables;
  response: AccountChangeFullNameAndEmailMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fullName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "fullName",
        "variableName": "fullName"
      },
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "kind": "ObjectValue",
    "name": "requestData"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountChangeFullNameAndEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ChangeFullNameAndEmailMutation",
        "kind": "LinkedField",
        "name": "changeFullNameAndEmail",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountAbstractType",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccountChangeFullNameAndEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ChangeFullNameAndEmailMutation",
        "kind": "LinkedField",
        "name": "changeFullNameAndEmail",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountAbstractType",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1abe50a77c908b66efbc3c1ad133a26f",
    "id": null,
    "metadata": {},
    "name": "AccountChangeFullNameAndEmailMutation",
    "operationKind": "mutation",
    "text": "mutation AccountChangeFullNameAndEmailMutation(\n  $email: String\n  $username: String\n  $fullName: String\n) {\n  changeFullNameAndEmail(requestData: {email: $email, username: $username, fullName: $fullName}) {\n    success\n    account {\n      email\n      username\n      fullName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "97a4573dcf6397a036fc40e37df43709";

export default node;
