import { useCallback, useState } from 'react';
import { noop } from 'lodash';
import { Hash } from 'viem';
import { type Connector, useConnect } from 'wagmi';

import { MPFonts, MPSelector } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import supportedWallets from 'constants/supportedWallets';
import useMPConnect from 'hooks/wallet/useMPConnect';
import CSSGlobal from 'types/enums/css/Global';
import isWalletError from 'utils/errors/wallet';
import {
  get24PxConnectorIcon,
  getConnectorLink,
  getConnectorName,
} from 'utils/wallet/connectorUtils';

interface ConnectorSelectorProps {
  onConnection?: (accounts: readonly Hash[], connector: Connector) => void;
  onError?: (e: Error) => void;
  onMutate?: Parameters<typeof useConnect>[0]['mutation']['onMutate'];
  onSettled?: Parameters<typeof useConnect>[0]['mutation']['onSettled'];
  onSuccess?: Parameters<typeof useConnect>[0]['mutation']['onSuccess'];
  title?: string;
}

export default function ConnectorSelector({
  onConnection = noop,
  onError = noop,
  onMutate = noop,
  onSettled = noop,
  onSuccess = noop,
  title = 'Choose how you want to connect from these wallet providers.',
}: ConnectorSelectorProps) {
  const [currentPendingConnectorId, setCurrentPendingConnectorId] =
    useState<string>();

  const { connectAsync, findConnector, isPending } = useMPConnect({
    mutation: { onError, onMutate, onSettled, onSuccess },
  });

  const handleConnectClick = useCallback(
    async (connector: Connector) => {
      try {
        setCurrentPendingConnectorId(connector.id);
        const connection = await connectAsync({ connector });
        onConnection(connection.accounts, connector);
      } catch (error) {
        setCurrentPendingConnectorId(undefined);
        if (isWalletError.ConnectorAlreadyConnected(error)) {
          const accounts = await connector.getAccounts();
          onConnection(accounts, connector);
        } else {
          onError(error);
        }
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [connectAsync, onConnection, onError]
  );

  return (
    <>
      <div
        className={joinClasses(
          MPFonts.paragraphNormal,
          CSSGlobal.Cursor.Pointer
        )}
      >
        {title}
      </div>
      <MPSelector
        items={supportedWallets.map((id) => {
          const connector = findConnector(id);
          return {
            disabled: false,
            isDisabled: isPending,
            loading: id === currentPendingConnectorId,
            onClick: !connector
              ? () => window.open(getConnectorLink(id))
              : () => {
                  if (isPending) {
                    return;
                  }
                  handleConnectClick(connector);
                },
            prefix: get24PxConnectorIcon(id),
            suffix: null,
            title: getConnectorName(id),
            variant: 'dark',
          };
        })}
      />
    </>
  );
}
