/**
 * @generated SignedSource<<76a41db6d31f5142a3cbf42bed9b1f20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OpenEditionBeforePurchaseInETHArguments = {
  buyerAddress: string;
  count: number;
  openEditionId: number;
  isPresale?: boolean | null;
  emailAddress?: string | null;
};
export type OpenEditionBeforePurchaseInETHMutation$variables = {
  requestData: OpenEditionBeforePurchaseInETHArguments;
};
export type OpenEditionBeforePurchaseInETHMutation$data = {
  readonly openEditionBeforePurchaseInEth: {
    readonly success: boolean | null;
    readonly response: {
      readonly depositRequestId: number | null;
      readonly depositMetadataId: number | null;
      readonly depositMetadataMarketPriceInWei: any | null;
    } | null;
  } | null;
};
export type OpenEditionBeforePurchaseInETHMutation = {
  variables: OpenEditionBeforePurchaseInETHMutation$variables;
  response: OpenEditionBeforePurchaseInETHMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "OpenEditionBeforePurchaseInETHMutation",
    "kind": "LinkedField",
    "name": "openEditionBeforePurchaseInEth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OpenEditionPurchaseDepositDataType",
        "kind": "LinkedField",
        "name": "response",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "depositRequestId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "depositMetadataId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "depositMetadataMarketPriceInWei",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OpenEditionBeforePurchaseInETHMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OpenEditionBeforePurchaseInETHMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b76ff0a06c6226bf81de80a4c59985dc",
    "id": null,
    "metadata": {},
    "name": "OpenEditionBeforePurchaseInETHMutation",
    "operationKind": "mutation",
    "text": "mutation OpenEditionBeforePurchaseInETHMutation(\n  $requestData: OpenEditionBeforePurchaseInETHArguments!\n) {\n  openEditionBeforePurchaseInEth(requestData: $requestData) {\n    success\n    response {\n      depositRequestId\n      depositMetadataId\n      depositMetadataMarketPriceInWei\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b483089bb05efc2b47408194e236593";

export default node;
