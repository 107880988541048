/**
 * @generated SignedSource<<a386d20d5194006de18346dc87f2fe7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenerateNonceArguments = {
  walletAddress: string;
};
export type SessionGenerateNonceMutation$variables = {
  requestData: GenerateNonceArguments;
};
export type SessionGenerateNonceMutation$data = {
  readonly generateSignatureMessage: {
    readonly key: string | null;
    readonly message: string | null;
    readonly nonce: string | null;
    readonly termsOfServiceUrl: string | null;
    readonly walletAddress: string | null;
  } | null;
};
export type SessionGenerateNonceMutation = {
  variables: SessionGenerateNonceMutation$variables;
  response: SessionGenerateNonceMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "GenerateNonceMutation",
    "kind": "LinkedField",
    "name": "generateSignatureMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nonce",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "termsOfServiceUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "walletAddress",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionGenerateNonceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SessionGenerateNonceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e345f88153258e08ca45842d32fe9a49",
    "id": null,
    "metadata": {},
    "name": "SessionGenerateNonceMutation",
    "operationKind": "mutation",
    "text": "mutation SessionGenerateNonceMutation(\n  $requestData: GenerateNonceArguments!\n) {\n  generateSignatureMessage(requestData: $requestData) {\n    key\n    message\n    nonce\n    termsOfServiceUrl\n    walletAddress\n  }\n}\n"
  }
};
})();

(node as any).hash = "371bcd02cf40c598da9d36822a6dbecb";

export default node;
