export enum MPFonts {
  attributeEdition = 'attributeEditionFont',
  attributeNumber = 'attributeNumberFont',
  attributeText = 'attributeTextFont',
  attributeTitle = 'attributeTitleFont',
  bodyLarge = 'bodyLargeFont',
  bodyMedium = 'bodyMediumFont',
  bodySmall = 'bodySmallFont',
  buttonMedium = 'buttonMediumFont',
  buttonSmall = 'buttonSmallFont',
  buttonToggle = 'buttonToggleFont',
  caption = 'captionFont',
  filterText = 'filterTextFont',
  headline1 = 'headline1Font',
  headline2 = 'headline2Font',
  headline3 = 'headline3Font',
  headline4 = 'headline4Font',
  homepageHeader = 'homepageHeaderFont',
  inputLabel = 'inputLabelFont',
  inputText = 'inputTextFont',
  marketCardArtist = 'marketCardArtistFont',
  marketCardInsightsLabel = 'marketCardInsightsLabelFont',
  marketCardInsightsText = 'marketCardInsightsTextFont',
  marketCardOwned = 'marketCardOwnedFont',
  notificationBoldText = 'notificationBoldTextFont',
  notificationRegularText = 'notificationRegularTextFont',
  notificationSettingsLabel = 'notificationSettingsLabelFont',
  paragraphLarge = 'paragraphLargeFont',
  paragraphNormal = 'paragraphNormalFont',
  paragraphSmall = 'paragraphSmallFont',
  paragraphXSmall = 'paragraphXSmallFont',
  price = 'priceFont',
  productDetailsTitle = 'productDetailsTitleFont',
  subPrice = 'subPriceFont',
  subtitle1 = 'subtitle1Font',
  textLargeMedium = 'textLargeMediumFont',
  textLargeRegular = 'textLargeRegularFont',
  textNormalMedium = 'textNormalMediumFont',
  textNormalRegular = 'textNormalRegularFont',
  textSmallBold = 'textSmallBoldFont',
  textSmallMedium = 'textSmallMediumFont',
  textSmallRegular = 'textSmallRegularFont',
  textSmallSemiBold = 'textSmallSemiBoldFont',
  titleMedium = 'titleMediumFont',
  titleSmall = 'titleSmallFont',
  titleXS = 'titleXSFont',
}

