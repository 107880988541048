.dialogPadding {
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    position: relative;
}

.changePasswordDiv {
    margin-bottom: 0;
    padding-bottom: 0.8rem;
}

.changePasswordDiv > .changePasswordTextInput {
    margin-bottom: 1rem;
}

.changePasswordDiv > .changePasswordErrorMessage {
    margin-bottom: 0;
}
