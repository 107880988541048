import {
  MPActionButton,
  MPFonts,
  MPShadowBox,
} from '@mp-frontend/core-components';

import StoreLayout from '../StoreLayout';

function GiftsPage() {
  return (
    <StoreLayout>
      <h2 className={MPFonts.titleMedium}>Creations to Send</h2>

      <br />
      <br />

      <MPShadowBox>
        <p className={MPFonts.bodyLarge}>
          You don&#39;t have any creations to send.
        </p>

        <br />
        <br />

        <MPActionButton size="large">Upload a Creation</MPActionButton>
      </MPShadowBox>
    </StoreLayout>
  );
}

export default GiftsPage;
