.creationPage {
  flex: 1 1 0;
  padding: 8px 16px;
}
.pageTitle {
  margin-bottom: 18px;
}

.deletedCreationsTitle {
  margin-top: 18px;
  margin-bottom: 16px;
}

.creationImg {
  width: 100%;
}