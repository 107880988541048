import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { usePreloadedQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';

import { MPFonts, useIsMobile } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTContractQueryType, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import NFTsType, { NFTsQuery } from 'graphql/__generated__/NFTsQuery.graphql';

import { APP_NAME } from 'constants/Utils';
import GTM from 'GTM';
import { NFTType } from 'types/graphql/NFT';
import generateTitleText from 'utils/generateTitleText';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import setDocTitle from 'utils/setDocTitle';

import { FourOFour } from '../../components/ErrorBoundaries/FourOFourErrorBoundary';
import ProductLayout from './ProductLayout';
import { useGetNFTFromPreloadedQuery } from './ProductPage';
import ProductRelated from './productRelated';
import ProductRightRail from './ProductRightRail';
import ProductTopSection from './ProductTopSection';
import useVanishingNavbar from './useVanishingNavbar';

import * as styles from 'css/pages/product/ProductPage.module.css';

export const RELATED_IMAGE_LIMIT = 50;
export const RELATED_IMAGE_PAGE_SIZE_LIMIT = 10;

interface NFTContainerProps {
  approvalRegistryContractQuery: WithLoadQueryProps<NFTContractQuery>;
  nftsQuery: WithLoadQueryProps<NFTsQuery>;
  setNFTMetaData: Dispatch<SetStateAction<NFTType['metadata']>>;
  transferCoreContractQuery: WithLoadQueryProps<NFTContractQuery>;
}

function ProductContainer({
  approvalRegistryContractQuery: { queryRef: contractApprovalQueryRef },
  nftsQuery: { queryRef, invalidate },
  transferCoreContractQuery: { queryRef: transferCoreQueryRef },
  setNFTMetaData,
}: NFTContainerProps) {
  const nft = useGetNFTFromPreloadedQuery(queryRef);
  const [searchParams] = useSearchParams();
  const isMobile = useIsMobile();
  const { nftContract: approvalRegistryContract } =
    usePreloadedQuery<NFTContractQuery>(
      NFTContractQueryType,
      contractApprovalQueryRef
    );
  const { nftContract: transferCoreContract } =
    usePreloadedQuery<NFTContractQuery>(
      NFTContractQueryType,
      transferCoreQueryRef
    );

  const nftMetaData = useCallback(() => {
    if (nft) {
      setNFTMetaData(nft.metadata);
    }
  }, [nft, setNFTMetaData]);
  setDocTitle(`${generateTitleText(nft)} | ${APP_NAME}`);
  useVanishingNavbar();

  const scrollDiv = document.querySelector(
    '#productPage'
  ) as HTMLElement | null;
  const scrollToTopButton = document.querySelector(
    '#scrollToTopButton'
  ) as HTMLElement | null;
  const scrollToTop = () => {
    if (!isMobile) {
      scrollDiv.style.overflow = 'auto';
      setTimeout(() => {
        scrollDiv.scrollTo(0, 0);
      }, 300);
    } else {
      scrollDiv.scrollIntoView({
        behavior: 'smooth',
      });
    }
    scrollToTopButton.style.transform = 'translate(-50%, -200px)';
  };

  useEffect(() => {
    if (isMobile) return () => undefined; // Don't run this hook on mobile devices

    const handleMouseMove = (e) => {
      // Calculate the distance from the top of the viewport
      const distanceFromTop = e.clientY;

      // Adjust this value to the desired threshold (e.g., 100 pixels)
      const threshold = 50;

      const scrollPosition =
        (scrollDiv.scrollTop / scrollDiv.scrollHeight) * 100;

      // Toggle the button's visibility based on the mouse position
      if (distanceFromTop <= threshold && scrollPosition >= 10) {
        scrollToTopButton.style.transform = 'translate(-50%, 116px)';
      }
    };

    // Attach the mousemove event listener to the scroll body
    if (scrollDiv) {
      scrollDiv.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      if (scrollDiv) {
        scrollDiv.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, [scrollDiv, scrollToTopButton, isMobile]);

  useEffect(() => {
    nftMetaData();
  }, [nftMetaData]);

  useEffect(() => {
    const carouselName = searchParams.get('carousel') ?? '';
    GTM.ecommerce.trackViewItem(
      nft,
      {
        item_category: 'pdp',
      },
      carouselName
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [nft.id]);

  if (!nft) return <FourOFour resetErrorBoundary={() => null} />;

  return (
    <>
      {createPortal(
        <button
          id="scrollToTopButton"
          type="button"
          onClick={scrollToTop}
          className={joinClasses(
            styles.backToTopButton,
            MPFonts.textSmallSemiBold
          )}
        >
          Back to the top
        </button>,
        document.getElementById('root'),
        'backToTopButton'
      )}

      <ProductLayout nftMetadata={nft.metadata}>
        <ProductTopSection
          nft={nft}
          invalidate={invalidate}
          approvalRegistryContract={approvalRegistryContract}
          transferCoreContract={transferCoreContract}
        />
        <ProductRightRail
          nft={nft}
          approvalRegistryContract={approvalRegistryContract}
          invalidate={invalidate}
        />
      </ProductLayout>

      <ProductRelated
        queryVariables={{
          digitalMediaId: parseInt(nft.metadata.pk, 10),
          first: RELATED_IMAGE_PAGE_SIZE_LIMIT,
          relatedImageLimit: RELATED_IMAGE_LIMIT,
        }}
      />
    </>
  );
}

export default withLoadQuery(ProductContainer, {
  approvalRegistryContractQuery: { concreteRequest: NFTContractQueryType },
  nftsQuery: { concreteRequest: NFTsType },
  transferCoreContractQuery: { concreteRequest: NFTContractQueryType },
});
