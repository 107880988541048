.sideNav {
  text-align: left;
  height: 100%;
  width: 200px;
  background-color: var(--color-commonWhite);
  z-index: 5;
  overflow-y: auto;
  border-right: 1px solid var(--color-commonSelectedGray);
  flex-shrink: 0;
}

.navList {
  padding-inline-start: 0;
  list-style: none;
  display: inline-block;
  background-color: var(--color-commonWhite);
  width: 100%;
}

.logo {
  text-align: center;
}

.seperator {
  border-bottom: 1px solid var(--color-commonSelectedGray);
}

.profileImage {
  width: 63px;
  height: 63px;
  border-radius: 9999px;
  z-index: 5;
}

.navItem {
  margin-top: 3px;
  margin-bottom: 1em;
  padding: 0 0.75em;
  width: 100%;
  box-sizing: border-box;
}

.navItem > a {
  color: var(--color-primaryP80);
  text-decoration: none;
  padding-left: 20px;
}

.navItem > a:hover {
  text-decoration: underline;
}
