import { Route } from 'react-router-dom';

import Reload from 'components/Reload';
import SlashRedirect from 'pages/SlashRedirect';

const ImportTokensRoute = (
  <Route path="import-tokens" element={<SlashRedirect />}>
    <Route path="*" element={<Reload />} />
  </Route>
);

export default ImportTokensRoute;
