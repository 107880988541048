.genres {
  border-bottom: 1px solid var(--color-primaryP20);
}

.title1 {
  margin: 76px 0 6px;
  text-align: center;
}

.title2 {
  margin: 0;
  text-align: center;
  letter-spacing: -3.264px;
  margin-bottom: 76px;
}

@media (--mq-is-mobile) {
  .title1, .title2 {
    letter-spacing: unset;
  }

  .title1 {
    margin: 76px 0 3px;
  }

  .title2 {
    margin-bottom: 52px;
  }

}
