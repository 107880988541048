/**
 * @generated SignedSource<<cc69e628dc129359839fb3c6719a86a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OwnDigitalMedias$variables = {
  cursor?: string | null;
  first?: number | null;
  isBurned?: boolean | null;
};
export type OwnDigitalMedias$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DigitalMediaOwn">;
};
export type OwnDigitalMedias = {
  variables: OwnDigitalMedias$variables;
  response: OwnDigitalMedias$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isBurned"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isBurned",
    "variableName": "isBurned"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OwnDigitalMedias",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DigitalMediaOwn"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OwnDigitalMedias",
    "selections": [
      {
        "alias": "results",
        "args": (v1/*: any*/),
        "concreteType": "DigitalMediaTypeV2Connection",
        "kind": "LinkedField",
        "name": "ownDigitalMediasV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DigitalMediaTypeV2Edge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DigitalMediaTypeV2",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pk",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isEditable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentPrintIndex",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalMissingCollabs",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalSupply",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "media500x500PreviewContent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": "results",
        "args": (v1/*: any*/),
        "filters": [
          "isBurned"
        ],
        "handle": "connection",
        "key": "OwnDigitalMedias__results",
        "kind": "LinkedHandle",
        "name": "ownDigitalMediasV2"
      }
    ]
  },
  "params": {
    "cacheID": "8c2c1f86bd5efc515b8f64eaae8fb201",
    "id": null,
    "metadata": {},
    "name": "OwnDigitalMedias",
    "operationKind": "query",
    "text": "query OwnDigitalMedias(\n  $cursor: String\n  $first: Int\n  $isBurned: Boolean\n) {\n  ...DigitalMediaOwn\n}\n\nfragment DigitalMediaOwn on Query {\n  results: ownDigitalMediasV2(first: $first, isBurned: $isBurned, after: $cursor) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        pk\n        title\n        isEditable\n        currentPrintIndex\n        totalMissingCollabs\n        totalSupply\n        media500x500PreviewContent\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff4dab42d705b19aececfd811355e435";

export default node;
