/**
 * @generated SignedSource<<94132e93c5ef4752760bbc4b8f8458ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserAccountType = "CREATOR" | "CONSUMER" | "ANON" | "DOWNGRADED_CREATOR";
export type LoginWithWalletArguments = {
  inviteCode?: string | null;
  signature: string;
  walletAddress: string;
  key: string;
};
export type SessionLoginWithWalletMutation$variables = {
  requestData: LoginWithWalletArguments;
};
export type SessionLoginWithWalletMutation$data = {
  readonly loginWithWallet: {
    readonly account: {
      readonly id: string;
      readonly username: string;
      readonly accountType: UserAccountType;
    } | null;
    readonly expireAt: any | null;
    readonly isAuthentic: boolean | null;
    readonly isNewUser: boolean | null;
    readonly token: string | null;
  } | null;
};
export type SessionLoginWithWalletMutation = {
  variables: SessionLoginWithWalletMutation$variables;
  response: SessionLoginWithWalletMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "LoginWithWalletMutation",
    "kind": "LinkedField",
    "name": "loginWithWallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountAbstractType",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountType",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expireAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAuthentic",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isNewUser",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionLoginWithWalletMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SessionLoginWithWalletMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "393d42f2755d079d756a43458b52a68b",
    "id": null,
    "metadata": {},
    "name": "SessionLoginWithWalletMutation",
    "operationKind": "mutation",
    "text": "mutation SessionLoginWithWalletMutation(\n  $requestData: LoginWithWalletArguments!\n) {\n  loginWithWallet(requestData: $requestData) {\n    account {\n      id\n      username\n      accountType\n    }\n    expireAt\n    isAuthentic\n    isNewUser\n    token\n  }\n}\n"
  }
};
})();

(node as any).hash = "3bdb0c6b58b73efb2de745066a177aed";

export default node;
