import { useCallback } from 'react';
import { useMutation } from 'react-relay';

import {
  MPActionButton,
  MPFonts,
  MPTooltip,
  useIsMobile,
} from '@mp-frontend/core-components';
import {
  DynamicTokenIcon,
  EtherscanIcon,
  HeightIcon,
  InfoIcon,
  MetadataIcon,
  RefreshIcon,
  ViewOriginalIcon,
  WidthIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTRefreshExternalTokenMetadataType, {
  NFTRefreshExternalTokenMetadataMutation,
} from 'graphql/__generated__/NFTRefreshExternalTokenMetadataMutation.graphql';

import useDownloadFile from 'hooks/files/useDownloadFile';
import CSSGlobal from 'types/enums/css/Global';
import useOnEnterKey from 'utils/eventHandlers/useOnEnterKey';
import { isNFTDynamic } from 'utils/nftUtils';

import ProductCollapsibleContainer from './ProductCollapsibleContainer';

import * as styles from 'css/pages/product/ProductDetailsMetadata.module.css';

interface ProductDetailsMetadataProps {
  canDownload?: boolean;
  canRefresh?: boolean;
  etherscanUrl?: string;
  height?: number;
  metadataUrl?: string;
  nftId?: number;
  nftTitle?: string;
  onRefresh?: () => void;
  rawMediaUrl?: string;
  rawfileExtension?: string;
  width?: number;
}

function ProductDetailsMetadata({
  canDownload,
  canRefresh,
  height,
  metadataUrl,
  nftId,
  nftTitle,
  rawMediaUrl,
  rawfileExtension,
  etherscanUrl,
  width,
  onRefresh,
}: ProductDetailsMetadataProps) {
  const isMobile = useIsMobile();
  const [download, isDownloading] = useDownloadFile();

  const [refreshTokenMetaDataMutation] =
    useMutation<NFTRefreshExternalTokenMetadataMutation>(
      NFTRefreshExternalTokenMetadataType
    );
  const handleManifoldTokenMetaDataRefresh = useCallback(
    () =>
      refreshTokenMetaDataMutation({
        onCompleted(response) {
          if (!response.refreshExternalTokenMetadata.success) return;

          onRefresh?.();
        },
        variables: { requestData: { nftId } },
      }),
    [refreshTokenMetaDataMutation, onRefresh, nftId]
  );
  const handleManifoldTokenMetaDataRefreshEnterKey = useOnEnterKey(
    handleManifoldTokenMetaDataRefresh
  );

  return (
    <ProductCollapsibleContainer title="Details">
      <div className={styles.container}>
        {isNFTDynamic(rawfileExtension) ? (
          <div
            className={joinClasses(
              MPFonts.textNormalRegular,
              CSSGlobal.Cursor.Default,
              styles.item
            )}
          >
            <DynamicTokenIcon />
            <div>Dynamic Token</div>
            <MPTooltip
              title="This token offers dynamic features and changes with external inputs (clicking on the token, changes in time of day, etc)."
              placement="bottom"
            >
              <InfoIcon className={styles.infoIcon} />
            </MPTooltip>
          </div>
        ) : null}
        {width ? (
          <div
            className={joinClasses(
              MPFonts.textNormalRegular,
              CSSGlobal.Cursor.Default,
              styles.item
            )}
          >
            <WidthIcon />
            <div>Width {width}px</div>
          </div>
        ) : null}
        {height ? (
          <div
            className={joinClasses(
              MPFonts.textNormalRegular,
              CSSGlobal.Cursor.Default,
              styles.item
            )}
          >
            <HeightIcon />
            <div>Height {height}px</div>
          </div>
        ) : null}
        {rawMediaUrl ? (
          <a
            href={rawMediaUrl}
            target="_blank"
            rel="noreferrer"
            className={joinClasses(
              MPFonts.textNormalRegular,
              CSSGlobal.Cursor.Pointer,
              styles.item
            )}
          >
            <ViewOriginalIcon />
            <div>View Original</div>
          </a>
        ) : null}
        {metadataUrl ? (
          <a
            href={metadataUrl}
            target="_blank"
            rel="noreferrer"
            className={joinClasses(
              MPFonts.textNormalRegular,
              CSSGlobal.Cursor.Pointer,
              styles.item
            )}
          >
            <MetadataIcon />
            <div>Metadata</div>
          </a>
        ) : null}
        {etherscanUrl ? (
          <a
            href={etherscanUrl}
            target="_blank"
            rel="noreferrer"
            className={joinClasses(
              MPFonts.textNormalRegular,
              CSSGlobal.Cursor.Pointer,
              styles.item
            )}
          >
            <EtherscanIcon />
            <div>Etherscan</div>
          </a>
        ) : null}
        {canRefresh && nftId ? (
          <div
            role="button"
            tabIndex={0}
            className={joinClasses(
              MPFonts.textNormalRegular,
              CSSGlobal.Cursor.Pointer,
              styles.item
            )}
            onClick={handleManifoldTokenMetaDataRefresh}
            onKeyPress={handleManifoldTokenMetaDataRefreshEnterKey}
          >
            <RefreshIcon />
            <div>Refresh</div>
          </div>
        ) : null}
      </div>
      {!!canDownload && !!rawMediaUrl && (
        <div className={styles.button}>
          <MPActionButton
            variant="secondary"
            fullWidth
            size={isMobile ? 'medium' : 'large'}
            onClick={() => download(rawMediaUrl, nftTitle)}
            disabled={isDownloading}
          >
            {isDownloading ? 'Downloading' : 'Download Original Artwork'}
          </MPActionButton>
        </div>
      )}
    </ProductCollapsibleContainer>
  );
}

export default ProductDetailsMetadata;
