import { Route } from 'react-router-dom';

import Reload from 'components/Reload';
import SlashRedirect from 'pages/SlashRedirect';

const YourRoute = (
  <Route path="your/" element={<SlashRedirect />}>
    <Route path="*" element={<Reload />} />
  </Route>
);

export default YourRoute;
