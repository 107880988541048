.storePageContent {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.storePageContentRight {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  overflow-y: overlay;
}
