import {
  Dispatch,
  SetStateAction,
  startTransition,
  useEffect,
  useState,
} from 'react';
import AuthCode from 'react-auth-code-input';
import { useMutation } from 'react-relay';

import {
  MPActionButton,
  MPDialog,
  useIsMobile,
} from '@mp-frontend/core-components';

import AccountFinalizeTwoFactorSetup, {
  AccountFinalizeTwoFactorSetupMutation,
} from 'graphql/__generated__/AccountFinalizeTwoFactorSetupMutation.graphql';
import AccountSetUpTwoFactor, {
  AccountSetUpTwoFactorMutation,
} from 'graphql/__generated__/AccountSetUpTwoFactorMutation.graphql';

import { useRefreshSession } from 'hooks/useSession';

import * as styles from 'css/pages/store/editAccount/TwoFactorDialog.module.css';

interface TwoFactorDialogProps {
  cancel: () => void;
  channel: string;
  isOpen: boolean;
  setSendCodeError: Dispatch<SetStateAction<string>>;
  onSendCodeSuccess?: () => void;
  phoneNumber?: string;
}

function TwoFactorDialog({
  cancel,
  channel,
  isOpen,
  onSendCodeSuccess,
  setSendCodeError,
  phoneNumber,
}: TwoFactorDialogProps) {
  const isMobile = useIsMobile();

  const [setUpTwoFactor] = useMutation<AccountSetUpTwoFactorMutation>(
    AccountSetUpTwoFactor
  );

  const [hasSent2FA, setHasSent2FA] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setUpTwoFactor({
        onCompleted: () => {
          setSendCodeError('');
          setHasSent2FA(true);
          if (onSendCodeSuccess) {
            onSendCodeSuccess();
          }
        },
        onError: (error) => {
          setSendCodeError(error.message);
          cancel();
        },
        variables: { channel, phoneNumber },
      });
    }
  }, [
    cancel,
    channel,
    isOpen,
    onSendCodeSuccess,
    phoneNumber,
    setSendCodeError,
    setHasSent2FA,
    setUpTwoFactor,
  ]);

  const [finalizeTwoFactorSetup, isFinalizingTwoFactorSetup] =
    useMutation<AccountFinalizeTwoFactorSetupMutation>(
      AccountFinalizeTwoFactorSetup
    );

  const [codeFor2FA, setCodeFor2FA] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const refreshSession = useRefreshSession();

  const submitButton = (
    <MPActionButton
      onClick={() => {
        finalizeTwoFactorSetup({
          onCompleted: () => {
            setErrorMessage('');
            startTransition(() => {
              refreshSession();
              cancel();
            });
          },
          onError: (error) => {
            setErrorMessage(error.message);
          },
          variables: { channel, code: codeFor2FA },
        });
      }}
      disabled={isFinalizingTwoFactorSetup}
    >
      Confirm
    </MPActionButton>
  );

  return (
    hasSent2FA && (
      <MPDialog
        onClose={cancel}
        open={isOpen}
        actionButton={submitButton}
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: 'var(--maxDialogHeight)',
            width: isMobile ? '100%' : '500px',
          },
        }}
        title="Two-Factor Verification"
      >
        <div className={styles.dialogContents}>
          <p>
            A one-time code has been sent via&nbsp;
            {channel === 'sms' ? 'SMS' : channel}.
          </p>
          <p>
            Enter it below to&nbsp;
            <span className="bold">
              Enable {channel === 'sms' ? 'SMS' : 'Email'} 2FA
            </span>
            .
          </p>
          <AuthCode
            inputClassName={styles.inputFor2FA}
            onChange={setCodeFor2FA}
          />
          {!!errorMessage && (
            <div className={styles.codeInputError}>{errorMessage}</div>
          )}
        </div>
      </MPDialog>
    )
  );
}

export default TwoFactorDialog;
