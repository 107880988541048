import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  startTransition,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import { useMutation } from 'react-relay';

import {
  MPActionButton,
  MPFonts,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CreateReservePriceMutation from 'graphql/__generated__/ListingCreateReservePriceMutation.graphql';

import Tabs from 'components/Tabs';
import { useHandleSetETH, useHandleSetUSD } from 'hooks/pricing/useMax';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTType } from 'types/graphql/NFT';
import toNumericShorthand from 'utils/numericShorthand';

import { ProductOwnerStates } from './ProductOwnerUpdateListing';

import * as styles from '../../../css/pages/product/ProductSetBuyNow.module.css';

const TABS = [CurrencyDisplayMode.ETH, CurrencyDisplayMode.USD].map(
  (currency: CurrencyDisplayMode) => ({
    label: currency,
    to: '#',
    value: currency,
  })
);

interface ProductSetReserveProps {
  nft: NFTType;
  setOwnershipState: Dispatch<SetStateAction<ProductOwnerStates>>;
}

function ProductSetReserve({ nft, setOwnershipState }: ProductSetReserveProps) {
  const [activeCurrency, setActiveCurrency] = useState<CurrencyDisplayMode>(
    nft.listing?.reserveCurrency === CurrencyDisplayMode.USD
      ? CurrencyDisplayMode.USD
      : CurrencyDisplayMode.ETH
  );

  const [reservePriceUSD, setReservePriceUSD] = useState<string>(
    nft.listing.hasReservePrice && activeCurrency === CurrencyDisplayMode.USD
      ? nft.listing.reservePriceInUsd?.toString()
      : null
  );

  const [reservePriceETH, setReservePriceETH] = useState<string>(
    nft.listing.hasReservePrice && activeCurrency === CurrencyDisplayMode.ETH
      ? nft.listing.reservePriceInEth?.toString()
      : null
  );

  const [commitCreateReservePriceMutation] = useMutation(
    CreateReservePriceMutation
  );

  const [, handleSetReserveUSD] = useHandleSetUSD(setReservePriceUSD);
  const [, handleSetReserveETH] = useHandleSetETH(setReservePriceETH);
  const handleSetReservePriceListingAmount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      activeCurrency === CurrencyDisplayMode.USD
        ? handleSetReserveUSD(event)
        : handleSetReserveETH(event);
    },
    [activeCurrency, handleSetReserveUSD, handleSetReserveETH]
  );

  const updateDelistingState = () => {
    startTransition(() => {
      setOwnershipState(ProductOwnerStates.DELIST);
    });
  };

  const dollarAmount =
    activeCurrency === CurrencyDisplayMode.ETH && reservePriceETH
      ? toNumericShorthand(parseFloat(reservePriceETH) * nft.currentEthRate)
      : 0;
  const ethAmount =
    activeCurrency === CurrencyDisplayMode.USD && reservePriceUSD
      ? toNumericShorthand(parseFloat(reservePriceUSD) / nft.currentEthRate)
      : 0;

  const createReservePriceWithETH = async ({ productId, reservePrice }) =>
    new Promise((resolve, reject) => {
      commitCreateReservePriceMutation({
        onCompleted: (data: any) => {
          resolve(data);
        },
        onError(error) {
          reject(error);
        },
        variables: {
          request_data: {
            availableForSale: true,
            productId,
            reserveCurrency: CurrencyDisplayMode.ETH,
            reservePrice,
          },
        },
      });
    });

  const createReservePriceWithUSD = async ({ productId, reservePrice }) =>
    new Promise((resolve, reject) => {
      commitCreateReservePriceMutation({
        onCompleted: (data: any) => {
          resolve(data);
        },
        onError(error) {
          reject(error);
        },
        variables: {
          request_data: {
            availableForSale: true,
            productId,
            reserveCurrency: CurrencyDisplayMode.USD,
            reservePrice,
          },
        },
      });
    });

  const [isValidatingTransaction, setIsValidatingTransaction] =
    useState<boolean>(false);
  const canDelist = nft.listing.liveSale || nft.listing.hasReservePrice;
  const isValidAmount =
    parseFloat(reservePriceETH) > 0 || parseFloat(reservePriceUSD) > 0;
  const isDisabled =
    (activeCurrency === CurrencyDisplayMode.USD
      ? !reservePriceUSD
      : !reservePriceETH) ||
    isValidatingTransaction ||
    !isValidAmount;

  const confirmListing = async () => {
    try {
      setIsValidatingTransaction(true);
      if (activeCurrency === CurrencyDisplayMode.USD) {
        await createReservePriceWithUSD({
          productId: parseInt(nft.listing.pk, 10),
          reservePrice: reservePriceUSD,
        });
      } else {
        await createReservePriceWithETH({
          productId: parseInt(nft.listing.pk, 10),
          reservePrice: reservePriceETH,
        });
      }
      setIsValidatingTransaction(false);
      startTransition(() => {
        setOwnershipState(ProductOwnerStates.RESERVE_LISTED);
      });
    } catch (error) {
      setIsValidatingTransaction(false);
    }
  };

  const displayReserveETH = reservePriceETH || '';
  const displayReserveUSD = reservePriceUSD || '';

  return (
    <div>
      <div className={styles.productSellContainer}>
        <div
          className={joinClasses(
            MPFonts.paragraphNormal,
            styles.productSellMessageContainer
          )}
        >
          A 24 hour countdown will begin once an offer is made that meets the
          reserve price. The highest offer will be accepted after 24 hours.
        </div>
        <div>
          <Tabs
            currentTab={activeCurrency}
            tabs={TABS}
            onChange={(event: SyntheticEvent, value: CurrencyDisplayMode) =>
              setActiveCurrency(value)
            }
          />
          <div className={styles.productBuyNowReserveInputContainer}>
            {activeCurrency === CurrencyDisplayMode.USD ? (
              <MPStyledTextField
                startAdornment="$"
                value={displayReserveUSD ?? ''}
                onChange={handleSetReservePriceListingAmount}
                inputMode="decimal"
                placeholder="Amount In USD"
              />
            ) : (
              <MPStyledTextField
                value={displayReserveETH ?? ''}
                onChange={handleSetReservePriceListingAmount}
                inputMode="decimal"
                placeholder="Amount In ETH"
              />
            )}
          </div>
          <div className={styles.buyReservePricingContainer}>
            <div
              className={joinClasses(
                styles.buyNowPriceItem,
                MPFonts.textNormalMedium
              )}
            >
              Total
            </div>
            <div className={styles.buyNowPriceItem}>
              {activeCurrency === CurrencyDisplayMode.USD ? (
                <span className={MPFonts.price}>
                  ${reservePriceUSD ?? 0}&nbsp;
                </span>
              ) : (
                <span className={MPFonts.price}>
                  {reservePriceETH ?? ethAmount}&nbsp;ETH&nbsp;
                </span>
              )}
              {activeCurrency === CurrencyDisplayMode.USD ? (
                <span
                  className={joinClasses(
                    MPFonts.subPrice,
                    styles.buyReserveSecondaryPrice
                  )}
                >
                  {ethAmount ?? 0}&nbsp;ETH&nbsp;
                </span>
              ) : (
                <span
                  className={joinClasses(
                    MPFonts.subPrice,
                    styles.buyReserveSecondaryPrice
                  )}
                >
                  ${dollarAmount}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.productSellFooter}>
        <div className={styles.productSellListingBtnContainer}>
          <MPActionButton
            fullWidth
            onClick={confirmListing}
            disabled={isDisabled}
            size="large"
          >
            Confirm
          </MPActionButton>
          {!!canDelist && (
            <div className={styles.deslistingMessage}>
              <div className={MPFonts.textSmallMedium}>
                You can delist your artwork&nbsp;
                <span
                  className="anchorV2 pointer"
                  role="button"
                  tabIndex={0}
                  onClick={updateDelistingState}
                  onKeyDown={updateDelistingState}
                >
                  here
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductSetReserve;
