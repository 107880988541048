// TODO(brian): Hack to make this work, once the API is done remove
function parseCookie(str) {
  return str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});
}

export default function getToken() {
  return parseCookie(document.cookie).csrftoken;
}
