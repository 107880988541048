/**
 * @generated SignedSource<<6ef763e730f7b64221c612f471149c6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PurchaseInCreditCardArguments = {
  productId: number;
  rememberCard: boolean;
  isPresale?: boolean | null;
  discountCode?: string | null;
  paymentIntentId?: string | null;
  paymentMethodId?: string | null;
  sessionId?: string | null;
};
export type ListingPurchaseInCreditCardMutation$variables = {
  requestData: PurchaseInCreditCardArguments;
};
export type ListingPurchaseInCreditCardMutation$data = {
  readonly purchaseInCreditCard: {
    readonly intentId: string | null;
    readonly success: boolean | null;
  } | null;
};
export type ListingPurchaseInCreditCardMutation = {
  variables: ListingPurchaseInCreditCardMutation$variables;
  response: ListingPurchaseInCreditCardMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "PurchaseInCreditCardMutation",
    "kind": "LinkedField",
    "name": "purchaseInCreditCard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "intentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListingPurchaseInCreditCardMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListingPurchaseInCreditCardMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f4901aadb0404ae21306f95b0ba0d9b4",
    "id": null,
    "metadata": {},
    "name": "ListingPurchaseInCreditCardMutation",
    "operationKind": "mutation",
    "text": "mutation ListingPurchaseInCreditCardMutation(\n  $requestData: PurchaseInCreditCardArguments!\n) {\n  purchaseInCreditCard(requestData: $requestData) {\n    intentId\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc04aca6b5d572b00b4d9fd6b4d1820d";

export default node;
