import { ReactNode, useEffect, useRef, useState } from 'react';

import { useIsMobile } from '@mp-frontend/core-components';
import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import CSSGlobal from 'types/enums/css/Global';
import { DropSectionSubSectionItemsType } from 'types/graphql/Drop';
import { NFTType } from 'types/graphql/NFT';
import { getNFTAdditionalMedias, getNFTPrimaryMedia } from 'utils/nftUtils';

import ProductPreview from '../productPreview';
import ProductRightRailContainer from '../ProductRightRailContainer';

import * as styles from 'css/pages/product/card/ProductPageCard.module.css';

export interface ProductPageCardLayoutProps {
  children: ReactNode;
  nftMetadata: Pick<
    NFTType['metadata'],
    | 'hasVideo'
    | 'highResImage'
    | 'id'
    | 'nftAdditionalMediasRanked'
    | 'rawfileExtension'
    | 'standardImage'
    | 'thumbnailImage'
    | 'videoUrl'
  > & {
    isSoldOut?: DropSectionSubSectionItemsType['isSoldOut'];
  };
  topSection: ReactNode;
  generativeRendererUrl?: string;
}

export default function ProductPageCardLayout({
  children,
  nftMetadata,
  topSection,
  generativeRendererUrl,
}: ProductPageCardLayoutProps) {
  const isMobile = useIsMobile();

  const [containerRef, setContainerRef] = useRefState<HTMLDivElement>(null);
  const [contentRef, setContentRef] = useRefState<HTMLDivElement>(null);
  const [isContentVisible, setIsContentVisible] = useState<boolean>(false);
  const observerRef = useRef<IntersectionObserver>(null);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();
    if (!containerRef || !contentRef) return undefined;

    observerRef.current = new IntersectionObserver(
      (entries) =>
        setTimeout(
          () =>
            // we consider the target element visible if its height
            // is at least 90% of the root element's height
            // so it doesn't shake/jump when positioning changes
            setIsContentVisible(
              entries[0].target.getBoundingClientRect().height /
                entries[0].rootBounds.height <
                0.9
            ),
          100 // delayed, because of transitions height is not final immediately
        ),
      {
        root: containerRef,
        rootMargin: '0px',
        threshold: [0.4, 0.55, 0.7, 0.85, 1],
      }
    );

    observerRef.current.observe(contentRef);
    return () => {
      observerRef.current.unobserve(contentRef);
    };
  }, [children, containerRef, contentRef]);

  return (
    <div className={styles.productCardContainer}>
      {!!isMobile && topSection}
      <div className={styles.productCardGalleryFlex}>
        <ProductPreview
          primaryMedia={getNFTPrimaryMedia(nftMetadata, isMobile)}
          additionalMedias={getNFTAdditionalMedias(nftMetadata)}
          isSoldOut={nftMetadata.isSoldOut}
          showSoldOut
          generativeRendererUrl={generativeRendererUrl}
        />
      </div>

      {/*
       * the right rail in the ProductPageCard should be either centered
       * if it has a small amount of content or scrollable starting from the top
       * if it has content taller than the container's size
       */}
      <div
        ref={setContainerRef}
        className={joinClasses(
          CSSGlobal.NoScrollbar,
          styles.rightRailContainer,
          { [styles.scrollable]: !isContentVisible }
        )}
      >
        <ProductRightRailContainer
          ref={setContentRef}
          className={styles.rightRail}
        >
          {!isMobile && topSection}

          {children}
        </ProductRightRailContainer>
      </div>
    </div>
  );
}
