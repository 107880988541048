import ROUTES from 'constants/Routes';
import useProductTimers from 'hooks/product/useProductTimers';
import { DropSectionSubSectionItemsType } from 'types/graphql/Drop';
import { NFTType, PurchasableNFTType } from 'types/graphql/NFT';
import { nftHasRankedAuction } from 'utils/nftUtils';

import ProductActions from '../productActions';
import { ProductDescriptions } from '../ProductDescription';
import ProductLabels from '../productLabels';
import RankedAuction from '../rankedAuction';
import ProductPageCardLayout from './Layout';
import ProductCardTopSection from './ProductCardTopSection';

export interface ProductPageCardProps {
  invalidate: () => void;
  nft: PurchasableNFTType;
  nftMetadata: Pick<
    NFTType['metadata'],
    | 'author'
    | 'collaborators'
    | 'description'
    | 'descriptionImageUrl'
    | 'descriptionVideoUrl'
    | 'highResImage'
    | 'hasVideo'
    | 'id'
    | 'nftAdditionalMediasRanked'
    | 'pk'
    | 'title'
    | 'thumbnailImage'
    | 'rawfileExtension'
    | 'standardImage'
    | 'videoUrl'
  > & {
    collectorReward: {
      description: string;
    };
    nftCollection: Pick<
      NFTType['metadata']['nftCollection'],
      'collectionName' | 'slug'
    >;
    isSoldOut?: DropSectionSubSectionItemsType['isSoldOut'];
  };
}

function ProductPageCard({
  nft,
  nftMetadata,
  invalidate,
}: ProductPageCardProps) {
  const {
    showRankedAuctionTimer,
    showProductAuctionTimer,
    showDropsTimer,
    onAuctionUpdate,
    onProductTimerEnd,
  } = useProductTimers(nft);

  return (
    <ProductPageCardLayout
      nftMetadata={nftMetadata}
      topSection={
        <ProductCardTopSection
          link={ROUTES.NFT(nft.listing.productSlug)}
          nft={nft}
          nftMetadata={nftMetadata}
        />
      }
    >
      <ProductDescriptions
        artwork={{
          description: nftMetadata.description,
          videoPreviewImageUrl: nftMetadata.descriptionImageUrl,
          videoUrl: nftMetadata.descriptionVideoUrl,
        }}
        collectorReward={{
          description: nftMetadata.collectorReward?.description,
        }}
        unlockable={{
          description: nft.unlockable?.description,
        }}
      />
      {nftHasRankedAuction(nft) && (
        <RankedAuction
          collapsible
          hasBottomBorder
          nft={nft}
          onAuctionUpdate={onAuctionUpdate}
        />
      )}
      <ProductLabels
        nft={nft}
        isSoldOut={nftMetadata.isSoldOut}
        showDropsTimer={showDropsTimer}
        showProductAuctionTimer={showProductAuctionTimer}
        showRankedAuctionTimer={showRankedAuctionTimer}
        onProductTimerEnd={onProductTimerEnd}
      />
      <ProductActions
        nft={nft}
        showDropsTimer={showDropsTimer}
        showProductAuctionTimer={showProductAuctionTimer}
        showRankedAuctionTimer={showRankedAuctionTimer}
        invalidate={invalidate}
      />
    </ProductPageCardLayout>
  );
}

export default ProductPageCard;
