/**
 * @generated SignedSource<<0e49d0c25d4db75fa9266b4cc93c06c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountResendVerificationEmailMutation$variables = {};
export type AccountResendVerificationEmailMutation$data = {
  readonly resendVerificationEmail: {
    readonly success: boolean | null;
  } | null;
};
export type AccountResendVerificationEmailMutation = {
  variables: AccountResendVerificationEmailMutation$variables;
  response: AccountResendVerificationEmailMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ResendVerificationEmailMutation",
    "kind": "LinkedField",
    "name": "resendVerificationEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountResendVerificationEmailMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountResendVerificationEmailMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "16dacd3af38570ff173474c36a754218",
    "id": null,
    "metadata": {},
    "name": "AccountResendVerificationEmailMutation",
    "operationKind": "mutation",
    "text": "mutation AccountResendVerificationEmailMutation {\n  resendVerificationEmail {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "5cedbd563b266406b8da3df8fb65ecc6";

export default node;
