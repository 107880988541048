.productRightRailContainer {
  cursor: default;
  width: var(--pdp-rail-width);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--pdp-padding) 0;
  margin: 0;

  @media (--mq-is-mobile) {
    width: 100%;
    margin: 24px 0px 0px;
    padding: 0;
  }
}

.productAuthorContainer {
  color: var(--color-SolidNeutralGray5);
  margin-top: 1px;
  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;

  & :global(.defaultLink) {
    color: var(--color-commonBlack);
  }
}

.productBadgeContainer {
  margin-bottom: 24px;
  width: 100%;

  @media (--mq-is-mobile) {
    margin-bottom: 0;

    & > hr {
      display: none;
    }
  }
}

.productBadgeContainerText {
  margin-bottom: 12px;
}
