.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (--mq-is-mobile) {
    grid-template-columns: 1fr;
  }
}

.card {
  padding: 32px;

  @media (--mq-is-mobile) {
    padding: 24px;
  }
}
