import { Outlet, Route } from 'react-router-dom';

import { FourOFour } from 'components/ErrorBoundaries/FourOFourErrorBoundary';
import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';
import { TrackingContext } from 'components/trackingContext';
import { EcommerceSourceType } from 'GTM';

import ArtworkPage from './artwork/ArtworkPage';

const ExploreRoute = (
  <Route
    path="explore"
    element={
      <PageErrorBoundary>
        <Outlet />
      </PageErrorBoundary>
    }
  >
    <Route
      path="artworks"
      element={
        <TrackingContext source={EcommerceSourceType.ExploreArtworksPage}>
          <ArtworkPage />
        </TrackingContext>
      }
    />
    <Route path="*" element={<FourOFour resetErrorBoundary={() => null} />} />
  </Route>
);

export default ExploreRoute;
