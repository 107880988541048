import { ReactNode } from 'react';

import { MPBackgroundColorClass } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/pages/about/AboutGrid.module.css';

export function AboutGridCard({ children }: { children: ReactNode }) {
  return (
    <div
      className={joinClasses(
        MPBackgroundColorClass.CommonWhite,
        CSSGlobal.Cursor.Default,
        styles.card
      )}
    >
      {children}
    </div>
  );
}

export default function AboutGrid({ children }: { children: ReactNode }) {
  return <div className={joinClasses(CSSGap[16], styles.grid)}>{children}</div>;
}
