.container {
  height: 484px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  flex: 0 0 auto;
  position: relative;
  color: var(--color-commonWhite);
  transition: width 240ms ease-in-out;
  cursor: pointer;

  @media (--mq-is-mobile) {
    height: 200px;
  }
}

.media {
  position: relative;
  object-fit: cover;
  width: inherit;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(20, 20, 20, 0.38);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  background: linear-gradient(
    180deg,
    rgba(20, 20, 20, 0.5) 0%,
    rgba(20, 20, 20, 0) 100%
  );
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
  padding: 24px;
  text-align: left;
  text-overflow: ellipsis;
  transition: font-size 240ms ease-in-out;
  white-space: nowrap;
}

.mediaInfo {
  background: linear-gradient(
    180deg,
    rgba(20, 20, 20, 0) 0%,
    rgba(20, 20, 20, 0.5) 100%
  );
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  opacity: 0;
  padding: 24px;
  transition: opacity 240ms ease-in-out;

  @media (--mq-is-mobile) {
    opacity: 1;
  }
}

.container.isActive .mediaInfo {
  opacity: 1;
}

.mediaArtist {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-align: left;
}

.mediaTitle {
  color: var(--color-SolidNeutralGray3);
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
