import { GenreEnum } from 'types/__generated__/graphql';

export enum Genres {
  All = 'ALL',
}

export const getGenre = (function GenerateGetGenre() {
  const Arr = Object.values(Genres);
  return function _getGenre(val) {
    return Arr.includes(val) ? val : Genres.All;
  };
})();

export const GenreTabs = [
  { label: 'All Genres', to: '#', value: Genres.All },
  { label: '3D Art', to: '#', value: GenreEnum.X3DArt },
  { label: 'AI', to: '#', value: GenreEnum.AiArt },
  { label: 'Animation', to: '#', value: GenreEnum.Animation },
  { label: 'Generative', to: '#', value: GenreEnum.GenerativeArt },
  { label: 'Illustration', to: '#', value: GenreEnum.Illustration },
  { label: 'Photography', to: '#', value: GenreEnum.Photography },
  { label: 'Unlockables', to: '#', value: GenreEnum.Phygitals },
];

export const GenreObj = GenreTabs.reduce((acc, tab) => {
  acc[tab.value] = tab.label;
  return acc;
}, {});
