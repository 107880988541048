.editAccountPageContent {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.editAccountPageContentRight {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  overflow-y: overlay;
}

.resendVerificationEmailText {
  text-align: right;
}

.resendVerificationEmailFeedback {
  padding-top: 0.5rem;
  text-align: right;
}

.walletApproval {
  padding-top: 1rem;
}

.walletApprovalStatus {
  padding-left: 2rem;
}

.creditCardRow {
  padding-top: 0.5rem;
}

.creditCardLogo {
  width: 5rem;
  padding-right: 1rem;
  vertical-align: middle;
}

.creditCardDelete {
  padding-left: 2rem;
}

.rightSideErrorMessage {
  padding-left: 1rem;
  color: var(--color-errorMain);
}

a.actionLink {
  margin: 16px 0;
  display: inline-block;
  cursor: pointer;
}

.onlyOneSession {
  color: var(--color-successMain);
}

.block {
  margin: 16px 0;
}

.textAlignCenter {
  text-align: center;
}
