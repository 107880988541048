import { useEffect, useState } from 'react';
import { Connector } from 'wagmi';

/**
 * @deprecated
 *
 * This was used to maintain a list of connectors and whether or not they were ready, Since we now only show
 * supported wallets, there is less of a need to maintain this list. We may want to revisit this to show
 * that a connector might not be ready yet, e.g. user clicking too fast. We should remove this once we're finished
 * with all the wallet related projects
 * @param connectors
 * @returns
 */
export default function useConnectorStatus(connectors: readonly Connector[]) {
  const [connectorStatus, setConnectorStatus] = useState<
    Record<string, boolean>
  >(
    connectors.reduce((acc, conn) => {
      acc[conn.id] = false;
      return acc;
    }, {})
  );

  useEffect(() => {
    connectors.forEach((connector) => {
      connector.getProvider().then((provider) =>
        setConnectorStatus((prev) => ({
          ...prev,
          [connector.id]: !!provider,
        }))
      );
    });
    // TODO: (Wagmi) check if this leaks
  }, [connectors]);

  return connectorStatus;
}
