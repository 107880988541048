.dialogContents {
  padding: 10px 20px 20px;
  text-align: center;
}

.inputFor2FA {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  border: 1px solid var(--color-grey800);
  border-radius: 4px;
  background-clip: padding-box;
}

.codeInputError {
  padding-top: 5px;
  color: var(--color-errorMain);
}
