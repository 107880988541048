.container {
  background-color: var(--color-SolidNeutralOffBlack);

  @media (--mq-is-mobile) {
    background-color: var(--color-commonBlack);
    padding-bottom: var(--heroCard-padding);
  }
}

.userRail {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "user"
    "stats";
  box-sizing: content-box;
  padding: 0 var(--heroCard-padding) var(--heroCard-padding);
  width: 342px;
}

.userContainer {
  grid-area: user;

  @media (--mq-is-mobile) {
    padding: 0 0 20px;
  }
}

.userLabel {
  margin-bottom: 2px;
}

.userStats {
  grid-area: stats;
  box-sizing: border-box;

  @media (--mq-is-mobile) {
    padding: 0 var(--heroCard-padding) 0;
  }
}

.userDescription {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (--mq-is-mobile) {
    gap: 16px;
    padding: 0 var(--heroCard-padding) 0;
  }
}

.user {
  @media (--mq-is-mobile) {
    padding: var(--heroCard-padding) var(--heroCard-padding) 0px;
  }
}

.artworkContainer {
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: center;
  padding: var(--heroCard-padding);

  @media (--mq-is-mobile) {
    background-color: var(--color-SolidNeutralOffBlack);
    height: 373px;
    padding: 26px;
  }
}

.artworkContainer > .artwork {
  cursor: pointer;
  filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.16));
  transform: scale(1);
  transition: transform 300ms ease-out;

  @media (--mq-is-not-mobile) {
    &:hover {
      transform: scale(1.02);
    }
  }
}

.artworkContainer > img.artwork,
.artworkContainer > picture.artwork,
.artworkContainer > picture.artwork > img,
.artworkContainer > video.artwork {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;

  @media (--mq-is-mobile) {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.artworkContainer > iframe.artwork {
  height: 100%;
  width: 100%;
}
