/**
 * @generated SignedSource<<10ef8df946e81945534aaffee1bf841e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OpenEditionPurchaseInETHArguments = {
  buyerAddress: string;
  count: number;
  depositMetadataId: number;
  nonce: number;
  openEditionId: number;
  transactionId: string;
  emailAddress?: string | null;
};
export type OpenEditionPurchaseInETHMutation$variables = {
  requestData: OpenEditionPurchaseInETHArguments;
};
export type OpenEditionPurchaseInETHMutation$data = {
  readonly openEditionPurchaseInEth: {
    readonly success: boolean | null;
  } | null;
};
export type OpenEditionPurchaseInETHMutation = {
  variables: OpenEditionPurchaseInETHMutation$variables;
  response: OpenEditionPurchaseInETHMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "OpenEditionPurchaseInETHMutation",
    "kind": "LinkedField",
    "name": "openEditionPurchaseInEth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OpenEditionPurchaseInETHMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OpenEditionPurchaseInETHMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "22648e495914af3f84f9f5c74a49d3dd",
    "id": null,
    "metadata": {},
    "name": "OpenEditionPurchaseInETHMutation",
    "operationKind": "mutation",
    "text": "mutation OpenEditionPurchaseInETHMutation(\n  $requestData: OpenEditionPurchaseInETHArguments!\n) {\n  openEditionPurchaseInEth(requestData: $requestData) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "38f8e156cab529a5bb611b1e93ad4acd";

export default node;
