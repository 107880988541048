import { useState } from 'react';
import { useAccount } from 'wagmi';

import { MPActionButton } from '@mp-frontend/core-components';

import WalletConnectionModal from 'components/WalletConnectionModal';
import useApprovedCreatorRegistryContract from 'hooks/contracts/useApprovedCreatorRegistryContract';
import useDigitalMediaCoreContract from 'hooks/contracts/useDigitalMediaCoreContract';
import { AccountConnectionStatus, HexString } from 'utils/jwt/walletUtils';

import * as styles from 'css/pages/store/EditAccountPage.module.css';

interface RevokeButtonHelperProps {
  isWalletDisconnected: () => boolean;
  openWalletConnectionModal: (callback: () => any) => void;
  saleContractAddress: string;
  tokenContractABI: any;
  tokenContractAddress: string;
}

function buildComponent(
  writeAsync,
  isError,
  error,
  isPending,
  isWalletDisconnected,
  openWalletConnectionModal,
  isFetching,
  isRefetching
) {
  const handleRevoke = async () => {
    if (isWalletDisconnected()) {
      // TODO: writeAsync is not triggering here.
      // Because connection just happened, writeAsync is still "reloading".
      // useEffect is probably the answer.
      openWalletConnectionModal(writeAsync);
    } else {
      await writeAsync();
    }
  };

  return (
    <>
      <MPActionButton
        type="button"
        size="large"
        onClick={handleRevoke}
        isLoading={isPending || isFetching || isRefetching}
      >
        Revoke
      </MPActionButton>
      {!!isError && (
        <span className={styles.rightSideErrorMessage}>
          Error Processing Revoke {error?.message}
        </span>
      )}
    </>
  );
}

function RevokeOBO({
  saleContractAddress,
  tokenContractABI,
  tokenContractAddress,
  isWalletDisconnected,
  openWalletConnectionModal,
}: RevokeButtonHelperProps) {
  // TODO: check that approval.address matches the wallet.

  const { useSetOboApprovalForAll } = useApprovedCreatorRegistryContract({
    abi: tokenContractABI,
    contractAddress: tokenContractAddress as HexString,
  });

  const setOboApprovalForAll = useSetOboApprovalForAll({
    isApproved: false,
    operatorAddress: saleContractAddress as HexString,
  });

  return buildComponent(
    setOboApprovalForAll.mutate.writeAsync,
    setOboApprovalForAll.mutate.isError,
    setOboApprovalForAll.mutate.error,
    setOboApprovalForAll.mutate.isPending,
    isWalletDisconnected,
    openWalletConnectionModal,
    setOboApprovalForAll.simulate.isPending,
    setOboApprovalForAll.simulate.isFetching
  );
}

function RevokeERC721({
  saleContractAddress,
  tokenContractABI,
  tokenContractAddress,
  isWalletDisconnected,
  openWalletConnectionModal,
}: RevokeButtonHelperProps) {
  // TODO: check that approval.address matches the wallet.

  const { useSetApprovalForAll } = useDigitalMediaCoreContract({
    abi: tokenContractABI,
    contractAddress: tokenContractAddress as HexString,
  });

  const setApprovalForAll = useSetApprovalForAll({
    approved: false,
    operatorAddress: saleContractAddress,
  });

  return buildComponent(
    setApprovalForAll.mutate.writeAsync,
    setApprovalForAll.mutate.isError,
    setApprovalForAll.mutate.error,
    setApprovalForAll.mutate.isPending,
    isWalletDisconnected,
    openWalletConnectionModal,
    setApprovalForAll.simulate.isPending,
    setApprovalForAll.simulate.isFetching
  );
}

interface RevokeButtonProps {
  isOBO: boolean;
  saleContractAddress: string;
  tokenContractABI: any;
  tokenContractAddress: string;
}

function RevokeButton({
  isOBO,
  saleContractAddress,
  tokenContractABI,
  tokenContractAddress,
}: RevokeButtonProps) {
  const { status: connectionStatus } = useAccount();

  let walletConnectionModalCallback;

  const [isWalletConnectionModalOpen, setIsWalletConnectionModalOpen] =
    useState(false);

  const openWalletConnectionModal = (callback: () => any) => {
    setIsWalletConnectionModalOpen(true);
    walletConnectionModalCallback = callback;
  };

  const isWalletDisconnected = () =>
    connectionStatus === AccountConnectionStatus.Disconnected;

  const onWalletConnection = () => {
    setIsWalletConnectionModalOpen(false);
    if (walletConnectionModalCallback) walletConnectionModalCallback();
  };

  const Button = isOBO ? RevokeOBO : RevokeERC721;

  return (
    <>
      <Button
        isWalletDisconnected={isWalletDisconnected}
        openWalletConnectionModal={openWalletConnectionModal}
        saleContractAddress={saleContractAddress}
        tokenContractABI={tokenContractABI}
        tokenContractAddress={tokenContractAddress}
      />
      <WalletConnectionModal
        isOpen={isWalletConnectionModalOpen}
        onConnection={onWalletConnection}
        onClose={() => setIsWalletConnectionModalOpen(false)}
      />
    </>
  );
}

export default RevokeButton;
