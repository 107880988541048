import { Fragment, useEffect } from 'react';
import { usePreloadedQuery } from 'react-relay';
import { Link } from 'react-router-dom';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTsMultiProvenanceQueryType, {
  NFTsMultiProvenanceQuery,
  NFTsMultiProvenanceQuery$data,
} from 'graphql/__generated__/NFTsMultiProvenanceQuery.graphql';
import { ProvenanceObjectType } from 'types/__generated__/graphql';

import ProfileIcon from 'components/avatar/ProfileIcon';
import ROUTES from 'constants/Routes';
import { DEFAULT_PROFILE_IMAGE_URL } from 'constants/Utils';
import { PurchasableNFTType } from 'types/graphql/NFT';
import generateEthAndUsdPricing from 'utils/currency/generateEthAndUsdPricing';
import generateEditionString from 'utils/generateEditionText';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import {
  WithLoadQueryPropsV1,
  withLoadQueryV1,
} from 'utils/hocs/withLoadQuery';

import * as styles from 'css/pages/product/ProductProvenance.module.css';

function BaseProductMultiEditionProvenance({
  queryRef,
  nft,
  onRefresh,
}: WithLoadQueryPropsV1<NFTsMultiProvenanceQuery> & {
  nft: PurchasableNFTType;
  onRefresh?: () => void;
}) {
  const { nftMultiEditionProvenance } =
    usePreloadedQuery<NFTsMultiProvenanceQuery>(
      NFTsMultiProvenanceQueryType,
      queryRef
    );

  useEffect(() => {
    onRefresh?.();
  }, [onRefresh, nftMultiEditionProvenance]);

  const getProvenanceLineDisplay = (
    data: NFTsMultiProvenanceQuery$data['nftMultiEditionProvenance'][0]
  ) => {
    const editionText = generateEditionString(
      data.edition,
      nft.metadata.totalSupply,
      'long'
    );
    if (
      data.type === ProvenanceObjectType.Sale ||
      data.type === ProvenanceObjectType.Listing
    ) {
      return (
        <>
          <span className={MPFonts.textNormalMedium}>{editionText}</span>
          &nbsp;listed for&nbsp;
          <span className={MPFonts.textNormalMedium}>
            {generateEthAndUsdPricing(data.amountEth, data.amountUsd)}
          </span>
        </>
      );
    }
    if (data.type === ProvenanceObjectType.Purchase) {
      return (
        <>
          <span className={MPFonts.textNormalMedium}>{editionText}</span>
          &nbsp;purchased for&nbsp;
          <span className={MPFonts.textNormalMedium}>
            {generateEthAndUsdPricing(data.amountEth, data.amountUsd)}
          </span>
        </>
      );
    }
    return (
      <>
        <span className={MPFonts.textNormalMedium}>{editionText}</span>
        &nbsp;minted&nbsp;
      </>
    );
  };

  return (
    <div className={styles.productProvenanceMultiContainer}>
      {nftMultiEditionProvenance.map((data) => (
        <Fragment key={`${data.edition}${data.store?.id}${nft.pk}`}>
          <div className={styles.productProvenanceMultiItem}>
            <div
              className={joinClasses(
                styles.avatar,
                styles.productProvenanceMultiItemAvatar
              )}
            >
              <Link
                to={data.store ? ROUTES.PROFILE.SHOP(data.store.storeSlug) : ''}
              >
                <ProfileIcon
                  source={
                    data.store
                      ? data.store.owner.profileImageUrl
                      : DEFAULT_PROFILE_IMAGE_URL
                  }
                  alt=""
                  className={styles.provenanceAvatar}
                />
              </Link>
            </div>
            <div className={styles.productMultiItemDescription}>
              <a
                className={styles.productMultiItemDescriptionLink}
                href={ROUTES.NFT(data.productSlug)}
              >
                {getProvenanceLineDisplay(data)}
              </a>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}

export const ProductMultiEditionProvenance = withDefaultErrorBoundary(
  withLoadQueryV1(
    BaseProductMultiEditionProvenance,
    NFTsMultiProvenanceQueryType
  )
);

interface ProductMultiEditionProvenanceExpandableProps {
  nft: PurchasableNFTType;
  onRefresh?: () => void;
}

function ProductMultiEditionProvenanceExpandable({
  nft,
  onRefresh,
}: ProductMultiEditionProvenanceExpandableProps) {
  return (
    <ProductMultiEditionProvenance
      queryVariables={{ nftMetadataId: parseInt(nft.metadata.pk, 10) }}
      onRefresh={onRefresh}
      nft={nft}
    />
  );
}

export default ProductMultiEditionProvenanceExpandable;
